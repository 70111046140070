const FEATURE_FLAGS: Record<string, string> = {
  isColorVPNReportEnabled: 'REACT_APP_COLOR_VPN_ENABLED',
} as const;

const ROLE_SIDS: Record<string, string> = {
  admin: 'REACT_APP_ADMIN_GROUP_SIDS',
} as const;

export type FeatureFlag = keyof typeof FEATURE_FLAGS;
export type Role = keyof typeof ROLE_SIDS;

export const stringToBoolean = (string: string) => string === 'true';

export const isFeatureFlagEnabled = (featureFlag: FeatureFlag) => {
  const envName = FEATURE_FLAGS[featureFlag];
  const envValue = process.env[envName];

  if (envValue && stringToBoolean(envValue)) {
    return true;
  }

  return false;
};

const getGroupSIDSFromLocalStorage = () => {
  const profileString = localStorage.getItem('profile');

  if (profileString === null) return [];

  try {
    const profile = JSON.parse(profileString);
    const groupSids = profile.userInfo['group_sids'] as Array<string>;

    return Array.isArray(groupSids) ? groupSids : [];
  } catch (error) {
    console.error('Error getting GROUP_SIDS', error);
    return [];
  }
};

const getGroupSIDSFromString = (groupSIDSString: string) => {
  if (!groupSIDSString || groupSIDSString.trim().length === 0) return [];

  return groupSIDSString.split(',');
};

const belongsToGroup = (groupSids: string[], whiteList: string[]) => {
  const isInGroup = whiteList.some((whiteListSID) => {
    if (groupSids.some((groupSID) => whiteListSID === groupSID)) return true;
  });

  return isInGroup;
};

export const hasRole = (role: Role) => {
  const envName = ROLE_SIDS[role];
  const envValue = process.env[envName];

  if (!envValue || envValue.trim() === '') return false;

  const groupSids = getGroupSIDSFromLocalStorage();
  const whiteListGroupSids = getGroupSIDSFromString(envValue);

  return belongsToGroup(groupSids, whiteListGroupSids);
};
