export const CHECK_TOKEN = 'CHECK_TOKEN';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const KEY_EXCHANGE_REQUEST = 'KEY_EXCHANGE_REQUEST';
export const KEY_EXCHANGE_SUCCESS = 'KEY_EXCHANGE_SUCCESS';
export const KEY_EXCHANGE_FAILURE = 'KEY_EXCHANGE_FAILURE';
export const GET_LOGIN_URL_REQUEST = 'GET_LOGIN_URL_REQUEST';
export const GET_LOGIN_URL_SUCCESS = 'GET_LOGIN_URL_SUCCESS';
export const GET_LOGIN_URL_FAILURE = 'GET_LOGIN_URL_FAILURE';

export const GET_LOGOUT_URL_REQUEST = 'GET_LOGOUT_URL_REQUEST';
export const GET_LOGOUT_URL_SUCCESS = 'GET_LOGOUT_URL_SUCCESS';
export const GET_LOGOUT_URL_FAILURE = 'GET_LOGOUT_URL_FAILURE';

export const GO_HOME = 'GO_HOME';

export const SUPPLIERS_REQUEST = 'SUPPLIERS_REQUEST';
export const SUPPLIERS_SUCCESS = 'SUPPLIERS_SUCCESS';
export const SUPPLIERS_FAILURE = 'SUPPLIERS_FAILURE';

export const SET_REPORTS_PARAMS = 'SET_REPORTS_PARAMS';

export const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const GET_REPORT_ERROR = 'GET_REPORT_ERROR';
export const SET_SUPPLIER = 'SET_SUPPLIER'; // This is for use by external users.
export const SET_SUPPLIER_ID = 'SET_SUPPLIER_ID'; // This is for use by employees
export const EMPLOYEE_GO_SUPPLIER = 'EMPLOYEE_GO_SUPPLIER';

export const SINGLE_SUPPLIER_REQUEST = 'SINGLE_SUPPLIER_REQUEST';
export const SINGLE_SUPPLIER_SUCCESS = 'SINGLE_SUPPLIER_SUCCESS';
export const SINGLE_SUPPLIER_FAILURE = 'SINGLE_SUPPLIER_FAILURE';
export const SINGLE_SUPPLIER_CLEAR = 'SINGLE_SUPPLIER_CLEAR';

export const GET_CONTENT_REQUEST = 'GET_CONTENT_REQUEST';
export const GET_CONTENT_SUCCESS = 'GET_CONTENT_SUCCESS';
export const GET_CONTENT_ERROR = 'GET_CONTENT_ERROR';

export const START_EDIT = 'START_EDIT';
export const CANCEL_EDIT = 'CANCEL_EDIT';

export const SAVE_CONTENT_REQUEST = 'SAVE_CONTENT_REQUEST';
export const SAVE_CONTENT_SUCCESS = 'SAVE_CONTENT_SUCCESS';
export const SAVE_CONTENT_ERROR = 'SAVE_CONTENT_ERROR';

export const EDIT_DRAFT = 'EDIT_DRAFT';

export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_ERROR = 'SEARCH_ERROR';
export const SEARCH_CHANGE_FOCUS = 'SEARCH_CHANGE_FOCUS';
export const SEARCH_VALID = 'SEARCH_VALID';
export const SEARCH_CLEAR = 'SEARCH_CLEAR';

export const RESOLVE_PENDING_ACCOUNT_ACTIONS =
  'RESOLVE_PENDING_ACCOUNT_ACTIONS';

export const GET_SUBSCRIPTION_TYPES_REQUEST = 'GET_SUBSCRIPTION_TYPES_REQUEST';
export const GET_SUBSCRIPTION_TYPES_SUCCESS = 'GET_SUBSCRIPTION_TYPES_SUCCESS';
export const GET_SUBSCRIPTION_TYPES_ERROR = 'GET_SUBSCRIPTION_TYPES_ERROR';

export const SELECT_SUBSCRIPTION_DETAIL_REQUEST =
  'SELECT_SUBSCRIPTION_DETAIL_REQUEST';
export const SELECT_SUBSCRIPTION_DETAIL_SUCCESS =
  'SELECT_SUBSCRIPTION_DETAIL_SUCCESS';
export const SELECT_SUBSCRIPTION_DETAIL_ERROR =
  'SELECT_SUBSCRIPTION_DETAIL_ERROR';

export const UPSERT_SUBSCRIPTION_REQUEST = 'UPSERT_SUBSCRIPTION_REQUEST';
export const UPSERT_SUBSCRIPTION_SUCCESS = 'UPSERT_SUBSCRIPTION_SUCCESS';
export const UPSERT_SUBSCRIPTION_ERROR = 'UPSERT_SUBSCRIPTION_ERROR';

export const SUBSCRIPTION_DETAIL_RESET = 'SUBSCRIPTION_DETAIL_RESET';
export const EXTRACT_REQUEST = 'EXTRACT_REQUEST';
export const EXTRACT_ERROR = 'EXTRACT_ERROR';
export const EXTRACT_SUCCESS = 'EXTRACT_SUCCESS';

export const REPORT_STATUS_REQUEST = 'REPORT_STATUS_REQUEST';
export const REPORT_STATUS_SUCCESS = 'REPORT_STATUS_SUCCESS';
export const REPORT_STATUS_ERROR = 'REPORT_STATUS_ERROR';
