import { ConfigObject } from './types';

const config: ConfigObject = {
  accountUrl: 'https://partneraccess-nonprod.nordstrom.com',
  appUrl: 'https://api-sandbox.nordstromaws.app/app01396-sandbox/vendorportal',
  authRedirectUrl: 'http://localhost:3000',
  authServiceUrl:
    'https://api-sandbox.nordstromaws.app/app01396-sandbox/vendorauthentication',
  reportApiUrl:
    'https://api-sandbox.nordstromaws.app/app01396-sandbox/vendorreporting',
  subscriptionsUrl:
    'https://api-sandbox.nordstromaws.app/app01396-sandbox/vendorsubscriptions',
};

export default config;
