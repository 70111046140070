import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import withStyles from 'material-ui/styles/withStyles';
import Alert from '../../components/generic/Alert';

const styles = (theme) => ({});

export class AuthExpired extends Component {
  render() {
    return (
      <div>
        <Alert
          variant='info'
          message='Your login has expired. Please log in again'
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loginUrl: state.auth.loginUrl,
    logoutUrl: state.auth.logoutUrl,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(AuthExpired);
