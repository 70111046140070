import React, { Component } from 'react';
import './suppliers.css';
import SupplierReport from './SupplierReport';

import { connect } from 'react-redux';
import { DropdownList } from 'react-widgets';
import { checkCurrentUserToken } from '../../utils/apiUtils';
import { setReportParameters } from '../../actions/suppliers';

import 'react-widgets/dist/css/react-widgets.css';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import withStyles from 'material-ui/styles/withStyles';
import { compose } from 'redux';
import Typography from 'material-ui/Typography/Typography';
import KeyboardBackspace from 'material-ui-icons/KeyboardBackspace';
import List from 'material-ui/List/List';
import Divider from 'material-ui/Divider/Divider';
import FormGroup from 'material-ui/Form/FormGroup';
import FormLabel from 'material-ui/Form/FormLabel';
import Alert from '../generic/Alert';

const styles = (theme) => ({
  contentMain: {
    width: '90%',
    margin: 'auto',
  },
  reportList: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
  },
  icon: {
    color: theme.palette.primary[700],
    marginRight: 16,
  },
  title: {
    display: 'flex',
    justifyItems: 'center',
  },
  group: {
    flex: 1,
  },
  form: {
    'marginTop': 20,
    'display': 'flex',
    'flexDirection': 'row',
    'justifyContent': 'space-around',
    '& legend': {
      marginBottom: '0.4em',
    },
  },
  dropdown: {
    fontSize: theme.typography.fontSize,
  },
  departmentBox: {
    padding: 10,
    borderRadius: 4,
    borderCollapse: 'collapse',
    border: '#ccc 1px solid',
    width: '100%',
    boxSizing: 'border-box',
    marginBottom: 10,
    marginTop: 10,
    background: theme.palette.white,
    height: 36,
    fontSize: theme.typography.fontSize,
  },
});

class Reports extends Component {
  constructor(props) {
    super(props);
    this.updateParams = this.updateParams.bind(this);
    this.setTextInputRef = this.setTextInputRef.bind(this);
    this.state = {
      departmentNumber: null,
      weeks: '1',
      departments: [],
      manual: '',
      lockCombo: false,
    };

    this.textInput = null;
  }
  setTextInputRef = (element) => {
    this.textInput = element;
  };
  focusTextInput = () => {
    // Focus the text input using the raw DOM API
    setTimeout(() => {
      if (this.textInput) this.textInput.focus();
    });
  };
  componentWillReceiveProps(nextProps) {
    this.props.checkCurrentUserToken();
  }

  componentWillMount() {
    this.props.checkCurrentUserToken();
    let { departments } = this.props.singleSupplier;
    departments = _.sortBy(departments, (d) => {
      return parseInt(d.departmentCode);
    });

    departments.push({ departmentCode: '', displayText: 'Other' });

    this.setState({
      manual: !departments.length,
      departments: departments,
      departmentNumber: departments.length ? departments[0].departmentCode : '',
    });

    if (this.state.manual == '') {
      setTimeout(() => {
        this.focusTextInput();
      });
    }
    window.scrollTo(0, 0);
  }

  state = {
    departmentNumber: '',
    weeks: '1',
  };

  setDepartmentNumber(value) {
    let v;

    if (_.isObject(value)) {
      v = value.departmentCode;
      if (v === '') {
        this.focusTextInput();
      }
    } else {
      v = value;
    }

    this.setState({ departmentNumber: v, manual: '' });
  }
  setNumWeeks(value) {
    this.setState({ weeks: value });

    this.props.setReportParameters('weeks', value);
  }

  updateParams(e) {
    const s = this.state;
    const value = null;

    if (e.target.name === 'weeks') {
      try {
        const tval = parseInt(e.target.value);
        if (tval > 0 && tval <= 26) {
          s.weeks = tval;
        } else {
          s.weeks = null;
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      s[e.target.name] = e.target.value;
      s.manual = e.target.value;
      if (s.manual === '') {
        s.lockCombo = false;
      } else {
        s.lockCombo = true;
      }
    }
    this.setState(s);
  }

  renderForm() {
    const { user, classes } = this.props;

    const filteredReports = this.props.reports.filter(
      (report) => !report.hidden
    );

    const backTip = user.isNordstromEmployee
      ? 'Back to search results'
      : 'Back to supplier list';

    if (this.props.singleSupplier && !this.props.loadingSingle) {
      const valid = this.state.departmentNumber && this.state.weeks;
      let reportList = null;
      const supplierForm = null;

      const { id, name } = this.props.singleSupplier;
      const { departments } = this.state;
      const { departmentNumber, weeks } = this.state;
      const allWeeks = _.range(1, 27);

      reportList = (
        <List className={classes.reportList}>
          {filteredReports.map((report, i) => (
            <SupplierReport
              supplierId={id}
              departmentNumber={departmentNumber}
              index={i}
              key={i}
              report={report}
              regions={this.props.regions}
            />
          ))}
        </List>
      );

      const wholeForm = (
        <div className={classes.contentMain}>
          <Typography className={classes.title} variant='title'>
            <Link title={backTip} alt={backTip} to={'/'}>
              <KeyboardBackspace className={classes.icon} />
            </Link>
            {name} - Supplier Reports
          </Typography>
          <Divider />
          <form className={classes.form}>
            <FormGroup className={classes.group} style={{ paddingRight: 20 }}>
              <FormLabel component='legend'>Department Number</FormLabel>
              <DropdownList
                data={departments}
                valueField='departmentCode'
                textField='displayText'
                defaultValue={this.state.departments[0].departmentCode}
                placeholder='Department ID'
                onChange={(value) => {
                  this.setDepartmentNumber(value);
                }}
                className={classes.dropdown}
              />

              {this.state.departmentNumber === '' || this.state.manual != '' ? (
                <div className='row'>
                  <div
                    className='col-md-6'
                    style={{ paddingRight: 0, lineHeight: '1em' }}
                  >
                    <input
                      className={classes.departmentBox}
                      ref={this.setTextInputRef}
                      placeholder='Department #'
                      onChange={this.updateParams}
                      name='departmentNumber'
                    />
                  </div>
                </div>
              ) : null}
            </FormGroup>
            <FormGroup className={classes.group}>
              <FormLabel component='legend'># Weeks</FormLabel>
              <DropdownList
                data={allWeeks}
                defaultValue='1'
                placeholder='# of Weeks'
                onChange={(value) => {
                  this.setNumWeeks(value);
                }}
                className={classes.dropdown}
              />
            </FormGroup>
          </form>

          {valid ? reportList : null}
        </div>
      );

      return wholeForm;
    }

    return null;
  }

  render() {
    if (!this.props.loadingSingle && this.props.singleError) {
      return (
        <Alert
          variant='error'
          message='The requested supplier could not be referenced.'
        />
      );
    }
    if (!this.props.loadingSingle && !this.props.singleSupplier) {
      return (
        <Alert
          variant='error'
          message='The requested supplier cannot be found in our records.'
        />
      );
    }

    return this.props.loadingSingle ? (
      <h1>Loading Supplier ...</h1>
    ) : (
      this.renderForm()
    );
  }
}

function mapStateToProps(state) {
  const { singleSupplier, query, loadingSingle, singleError } = state.suppliers;
  const { user } = state.auth;

  return { singleSupplier, query, user, loadingSingle, singleError };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { setReportParameters, checkCurrentUserToken })
)(Reports);
