import React, { Component } from 'react';
import { connect } from 'react-redux';

import './subscriptions.css';
import { clearSearch } from '../../actions/search';
import { selectSubscriptionDetail } from '../../actions/subscriptions';
import { compose } from 'redux';
import * as _ from 'lodash';
import SubscriptionsDetail from './SubscriptionsDetail';
import { withStyles } from 'material-ui/styles';
import Typography from 'material-ui/Typography/Typography';
import Table from 'material-ui/Table/Table';
import Paper from 'material-ui/Paper/Paper';
import TableHead from 'material-ui/Table/TableHead';
import TableRow from 'material-ui/Table/TableRow';
import TableCell from 'material-ui/Table/TableCell';
import TableBody from 'material-ui/Table/TableBody';

const styles = (theme) => ({
  root: {},
  paper: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    overflowY: 'scroll',
    maxHeight: '24rem',
  },
  table: {},
  head: {
    'position': 'sticky',
    'top': 0,
    'zIndex': 10,
    '& th': {
      fontSize: '18px',
      fontWeight: 500,
    },
  },
  row: {
    '&:hover': {
      backgroundColor: theme.palette.highlight,
      cursor: 'pointer',
    },
  },
});

class SubscriptionsSearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayResults: [],
      detailSupplier: null,
      open: false,
    };
  }

  componentWillMount() {
    //  this.props.clearSearch();
  }

  getSelectionClassName(v) {
    const { detailSupplier } = this.state;

    if (detailSupplier && detailSupplier.vendorNumber === v.vendorNumber) {
      return 'detailSelected';
    }
  }

  assignDetail(v) {
    this.setState({ detailSupplier: v });
    const { vendorNumber } = v;
    const subscriptionTypeId =
      this.props.subcriptionTypes && this.props.subcriptionTypes.length
        ? this.props.subscriptionTypes[0].id
        : 1;
    this.props.selectSubscriptionDetail({ vendorNumber, subscriptionTypeId });
    this.handleClickOpen();
  }

  renderSelection() {
    const { detailSupplier } = this.state;
    if (!detailSupplier) return null;
    if (this.props.subscriptionDetail) {
      return (
        <SubscriptionsDetail
          onClose={this.handleClose}
          currentSubscriptionTypeId={this.props.currentSubscriptionTypeId}
          subscription={this.props.subscriptionDetail}
          vendor={detailSupplier}
          subscriptionTypes={this.props.subscriptionTypes}
        />
      );
    }

    return null;
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  renderTable() {
    const { results, classes } = this.props;

    const filtered = _.chain(results)
      .filter(['roleName', 'VENDOR ORDER FROM'])
      .sortBy(['vendorName'])
      .value();

    this.state.displayResults = filtered;
    const spinner = null;

    if (filtered.length > 0) {
      return (
        <div>
          <Typography variant='subheading'>
            Vendor subscription results (<b>{filtered.length}</b> records found)
          </Typography>
          <Paper className={classes.paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.head}>
                  <TableCell>Vendor Name</TableCell>
                  <TableCell>Vendor Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.displayResults.map((row) => {
                  return (
                    <TableRow
                      className={classes.row}
                      onClick={() => {
                        this.assignDetail(row);
                      }}
                    >
                      <TableCell>{row.vendorName}</TableCell>
                      <TableCell>{row.vendorNumber}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
          {this.renderSelection()}
        </div>
      );
    } else {
      return (
        <div
          style={{ width: '100%', marginTop: '1em' }}
          className='alert alert-warning'
        >
          Your search returned no results
        </div>
      );
    }
  }

  render() {
    return this.props.results && this.props.complete
      ? this.renderTable()
      : null;
  }
}
const mapStateToProps = (state) => {
  const { error, loading, results, searchType, keepState, complete } =
    state.search;
  const { subscriptionTypes, subscriptionDetail, loadingSubscriptionDetail } =
    state.subscriptions;
  return {
    user: state.auth.user,
    error,
    loading,
    results,
    searchType,
    keepState,
    complete,
    subscriptionTypes,
    subscriptionDetail,
    loadingSubscriptionDetail,
  };
};
export default compose(
  withStyles(styles),
  connect(mapStateToProps, { clearSearch, selectSubscriptionDetail })
)(SubscriptionsSearchResults);
