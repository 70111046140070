import React, { Component } from 'react';
import { connect } from 'react-redux';
import { checkCurrentUserToken } from '../../utils/apiUtils';
import { getReport, postReport } from '../../actions/suppliers';
import * as _ from 'lodash';
import Button from 'material-ui/Button/Button';
import withStyles from 'material-ui/styles/withStyles';
import { compose } from 'redux';
import ExpansionPanel from 'material-ui/ExpansionPanel/ExpansionPanel';
import ExpansionPanelSummary from 'material-ui/ExpansionPanel/ExpansionPanelSummary';
import ExpandMoreIcon from 'material-ui-icons/ExpandMore';
import ClearIcon from 'material-ui-icons/Clear';
import ContentCopy from 'material-ui-icons/ContentCopy';
import Typography from 'material-ui/Typography/Typography';
import ExpansionPanelDetails from 'material-ui/ExpansionPanel/ExpansionPanelDetails';
import List from 'material-ui/List/List';
import Paper from 'material-ui/Paper/Paper';
import ListItem from 'material-ui/List/ListItem';
import LinearProgress from 'material-ui/Progress/LinearProgress';
import Alert from '../generic/Alert';
import FormLabel from 'material-ui/Form/FormLabel';
import TextField from 'material-ui/TextField/TextField';
import Chip from 'material-ui/Chip/Chip';
import Add from 'material-ui-icons/Add';
import PictureAsPdf from 'material-ui-icons/PictureAsPdf';

const styles = (theme) => ({
  root: {},
  panel: {
    'display': 'flex',
    'alignItems': 'row',
    'flexDirection': 'column',
    'flexShrink': 'inherit!important',
    'flexBasis': 'inherit!important',
    '&subheading': {
      fontSize: '24px!important',
    },
  },
  panelRoot: {},
  panelHeading: {
    color: 'inherit!important',
  },

  expanded: {
    '&$expanded': {
      'minHeight': '1em',
      'padding': '0 12px 0 12px',
      '&$content': {
        color: 'white',
      },
      'marginBottom': '-0.15em',
      'fontSize': 24,
    },
  },
  cmds: {
    display: 'flex',
    alignContent: 'space-around',
    alignItems: 'center',
  },
  cmdButton: {
    'flex': 1,
    'margin': 10,
    '&:hover': {
      color: theme.palette.secondary,
    },
    '&:active': {
      color: theme.palette.secondary,
    },
  },
  clearIcon: {
    color: theme.palette.error[300],
  },
  icon: {
    marginRight: '0.5em',
  },
  label: {
    marginRight: 10,
  },
  addVpn: {
    'display': 'flex',
    'flexDirection': 'row',
    '& button': {
      display: 'flex',
      alignSelf: 'flex-end',
    },
  },
  vpnList: {
    display: 'flex',
    minHeight: '8rem',
    flexFlow: 'row wrap',
    overflowY: 'scroll',
    justifyItems: 'stretch',
    alignItems: 'start',
  },
  vpnListItem: {
    flex: '0 1 10%',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  regionSelect: {
    minHeight: '12rem',
    marginTop: 8,
    padding: 10,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
  },
  regionBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  regionOption: {
    '&:checked': {
      backgroundColor: theme.palette.secondary,
    },
  },
  alert: {
    marginRight: 20,
  },
  chip: {},
  progress: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
  },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// TODO: This component needs to be factored out into smaller, easier-to-manage
//      Components.
class SupplierReport extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.addVpn = this.addVpn.bind(this);
    this.rmVpn = this.rmVpn.bind(this);
    this.generateReport = this.generateReport.bind(this);

    this.state = {
      open: false,
      selectedRegions: [],
      selectedVpns: [],
      loading: false,
      valid: true,
    };
  }
  componentWillReceiveProps(nextProps) {
    this.props.checkCurrentUserToken();
  }

  state = {
    open: false,
    loading: false,
    valid: true,
    selectedRegions: [],
    curVpn: '',
  };

  componentWillMount() {
    this.props.checkCurrentUserToken();

    // Removed demo/test vpns
    // this.state.selectedVpns.push("AW3978");
    // this.state.selectedVpns.push("AW4323");
    if (_.includes(this.props.report.parameters, 'vpn')) {
      this.setState({ valid: this.state.selectedVpns.length > 0 });
    }
    if (_.includes(this.props.report.parameters, 'regions')) {
      this.setState({ valid: this.state.selectedRegions.length > 0 });
    }
  }
  generateReport(e, fileType) {
    const { query, supplierId, report, departmentNumber } = this.props;

    const q = _.clone(this.props.query);

    if (!_.includes(['csv', 'pdf'], fileType)) {
      throw new Error('invalid report type sent to generateReport');
    }
    if (_.includes(this.props.report.parameters, 'vpn')) {
      q.vpns = this.state.selectedVpns;
    }

    if (_.includes(this.props.report.parameters, 'regions')) {
      q.regions = this.state.selectedRegions;
    }
    q.fileType = fileType;

    if (report.name === 'Supplier Color Size Detail') {
      // This report uses a POST request
      this.props.postReport(
        {
          supplierId,
          departmentId: departmentNumber,
          numberOfWeeks: q.weeks,
          vpns: q.vpns.join(','),
        },
        fileType,
        departmentNumber,
        report
      );
    } else if (report.name === 'Sales and Inventory by Dept-VPN/Color') {
      // This report uses a POST request
      this.props.postReport(
        {
          supplierId,
          departmentId: departmentNumber,
          numberOfWeeks: q.weeks,
        },
        fileType,
        departmentNumber,
        report
      );
    } else {
      this.props.getReport(q, supplierId, departmentNumber, report);
    }
  }
  onRegionClick(e) {
    const options = e.target.options || e.target.parentElement.options || [];
    const selectedOptions = [];

    for (let i = 0; i < options.length; i++) {
      const o = options[i];

      if (o.selected) {
        selectedOptions.push(o.value);
      }
    }

    const { selectedRegions } = this.state;
    const _this = this;
    setTimeout(function () {
      _this.setState({
        selectedRegions: selectedOptions,
      });
      _this.setState({ valid: _this.state.selectedRegions.length > 0 });
    }, 0);
  }

  handleChange = (fieldName) => (event) => {
    this.setState({
      [fieldName]: event.target.value,
      [fieldName + 'Box']: event.target,
    });
  };

  addVpn(e) {
    e.preventDefault();
    let vpns = this.state.selectedVpns;
    const { curVpn } = this.state;
    vpns.push(curVpn);
    vpns = _.uniq(vpns).sort();
    this.state.curVpnBox.select();
    this.state.curVpnBox.focus();

    this.setState({ selectedVpns: vpns, valid: true, curVpn: '' });
  }
  rmVpn(e, i) {
    let vpns = this.state.selectedVpns;

    vpns = _.without(vpns, vpns[i]);

    this.setState({ selectedVpns: vpns, valid: vpns.length > 0 });
  }
  toggle() {
    this.setState({ open: !this.state.open });
  }

  render() {
    let loading = false;
    let errMessage = null;
    const { classes } = this.props;

    const hasXOverflow = _.some(this.state.selectedVpns, (vpn) => {
      return vpn.length > 10;
    })
      ? 'widevpn'
      : '';

    if (this.props.reportLoadingStatus)
      loading =
        this.props.reportLoadingStatus[this.props.report.urlFragment] === true;
    let hasError = false;
    if (this.props.reportLoadingStatus) {
      hasError =
        !!this.props.reportLoadingStatus[this.props.report.urlFragment] &&
        this.props.reportLoadingStatus[this.props.report.urlFragment] !== true;

      const status =
        this.props.reportLoadingStatus[this.props.report.urlFragment];

      switch (status) {
        case 'Document contained no data':
          {
            errMessage = (
              <Alert
                variant='warning'
                message='Report contains no data, so no file was generated.'
              />
            );
          }
          break;
        default:
          {
            errMessage = (
              <Alert variant='error' message='Unable to generate report.' />
            );
          }
          break;
      }
    }

    const showRegions = _.includes(this.props.report.parameters, 'regions');
    const showVpn = _.includes(this.props.report.parameters, 'vpn');

    const renderRegions = (
      <div className={classes.regionBox}>
        <FormLabel>Select Region(s)</FormLabel>
        <select
          data-test-id='vpn_regions'
          className={classes.regionSelect}
          onClick={this.onRegionClick.bind(this)}
          defaultValue={this.state.selectedRegions}
          multiple
        >
          {this.props.regions.map((r, i) => {
            return (
              <option key={i} className={classes.regionOption} value={r.id}>
                {r.name} ({r.id})
              </option>
            );
          })}
        </select>
      </div>
    );

    const vpns = (
      <div>
        <form onSubmit={this.addVpn} className={classes.addVpn}>
          <TextField
            data-test-id='cur-vpn'
            label='VPN'
            className={classes.textField}
            value={this.state.curVpn}
            onChange={this.handleChange('curVpn')}
            margin='normal'
          />
          <Button
            disabled={!this.state.curVpn}
            type='submit'
            onClick={this.addVpn}
          >
            <Add />
            Add VPN
          </Button>
        </form>
        <Paper>
          <List className={classes.vpnList}>
            {this.state.selectedVpns.map((v, i) => {
              return (
                <ListItem className={classes.vpnListItem} key={i}>
                  <Chip
                    label={v}
                    className={classes.chip}
                    color='primary'
                    onDelete={(e) => {
                      this.rmVpn(e, i);
                    }}
                    deleteIcon={<ClearIcon />}
                  />
                </ListItem>
              );
            })}
          </List>
        </Paper>
      </div>
    );

    /*
    const expanded = (<div>
        <div className="reportexpanded"><p className="title">{this.props.report.name}</p></div>
        <div style={{marginLeft: "2em"}}>

            <div className="right">
               { loading ? working  : ready }
               { hasError ? errMessage : null }
            </div>
        </div>
    </div>);
    */
    const cmds = (
      <div>
        <div>
          {showRegions ? renderRegions : null}
          {showVpn ? vpns : null}
        </div>
        <div className={classes.cmds}>
          {this.props.report.fileType === 'pdf' ? (
            <Button
              variant='raised'
              data-test-id={this.props.report.tag + '_pdf'}
              className={classes.cmdButton}
              color='primary'
              onClick={(e) => {
                this.generateReport(e, 'pdf');
              }}
              disabled={!this.state.valid}
            >
              <PictureAsPdf className={classes.icon} />
              Generate PDF Report
            </Button>
          ) : (
            <Button
              variant='raised'
              data-test-id={this.props.report.tag + '_csv'}
              className={classes.cmdButton}
              color='secondary'
              onClick={(e) => {
                this.generateReport(e, 'csv');
              }}
              disabled={!this.state.valid}
            >
              <ContentCopy className={classes.icon} />
              Generate CSV Report
            </Button>
          )}
        </div>
      </div>
    );
    const loadingContent = (
      <div className={classes.progress}>
        <LinearProgress data-test-id='progress-indicator' />
      </div>
    );

    return (
      <ExpansionPanel className={classes.panelRoot}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{ root: classes.panelRoot, expanded: classes.expanded }}
        >
          <Typography variant='subheading' className={classes.panelHeading}>
            {this.props.report.name}
          </Typography>
        </ExpansionPanelSummary>
        {loading ? loadingContent : null}
        <ExpansionPanelDetails className={classes.panel}>
          {hasError ? errMessage : null}
          {!loading ? cmds : null}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

function mapStateToProps(state) {
  if (state.suppliers) {
    const { query, reportLoadingStatus } = state.suppliers;
    return { query, reportLoadingStatus };
  }
  return {};
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getReport, postReport, checkCurrentUserToken })
)(SupplierReport);
