import React, { Component } from 'react';
import HighlightOffIcon from 'material-ui-icons/HighlightOff';
import Typography from 'material-ui/Typography/Typography';

import { withStyles } from 'material-ui/styles';
import Paper from 'material-ui/Paper/Paper';
import Cancel from 'material-ui-icons/Cancel';
import PropTypes from 'prop-types';
import Announcement from 'material-ui-icons/Announcement';

import CheckCircle from 'material-ui/internal/svg-icons/CheckCircle';

const PROP_TYPES = {
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  message: PropTypes.string.isRequired,
  icon: PropTypes.object,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};

const DEFAULT_PROPS = {
  variant: 'info',
};

const styles = (theme) => ({
  paper: {
    backgroundColor: theme.palette.error[50],
    color: theme.palette.error[900],
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 10,
    padding: 10,
    minWidth: '100%',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  icon: {
    marginRight: 16,
  },
  message: {
    fontSize: '1.12rem',
  },
  success: {
    backgroundColor: theme.palette.success[50],
    color: theme.palette.success[900],
  },
  error: {
    backgroundColor: theme.palette.error[50],
    color: theme.palette.error[900],
  },
  warning: {
    backgroundColor: theme.palette.warning[50],
    color: theme.palette.warning[900],
  },
  info: {
    backgroundColor: theme.palette.info[50],
    color: theme.palette.info[900],
  },
});

class Alert extends Component {
  render() {
    let { icon, color, message, classes, variant, backgroundColor } =
      this.props;
    const iconColor = color || classes[variant];

    if (!icon) {
      switch (variant) {
        case 'info':
          icon = <Announcement className={classes.icon} />;
          break;
        case 'warning':
          icon = <Cancel className={classes.icon} />;
          break;
        case 'error':
          icon = <HighlightOffIcon className={classes.icon} />;
          break;
        case 'success':
          icon = <CheckCircle className={classes.icon} />;
          break;
      }
    }

    return (
      <Paper elevation={1} className={classes.paper + ' ' + classes[variant]}>
        {icon}
        <Typography className={classes.message + ' ' + classes[variant]}>
          {message}
        </Typography>
      </Paper>
    );
  }
}

Alert.propTypes = PROP_TYPES;
Alert.defaultProps = DEFAULT_PROPS;

export default withStyles(styles)(Alert);
