import React, { Component } from 'react';
import './suppliers.css';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { searchVendors, clearSearch } from '../../actions/search';
import { checkReporting } from '../../actions/suppliers';
import { checkCurrentUserToken } from '../../utils/apiUtils';

import SearchBox from '../generic/SearchBox';
import SearchResults from './SearchResults';
import Typography from 'material-ui/Typography/Typography';
import { withStyles } from 'material-ui/styles';
import Button from 'material-ui/Button';
import LinearProgress from 'material-ui/Progress/LinearProgress';
import Alert from '../generic/Alert';
import { FeatureFlag } from '../generic/FeatureFlag';

const styles = (theme) => ({
  heading: {
    fontSize: '1.3em',
  },
  cmds: {
    display: 'flex',
    alignContent: 'flex-fill',
    alignItems: 'center',
    flexDirection: 'row',
    flex: 5,
  },
  cmdButton: {
    'display': 'flex',
    'margin': 10,
    '&:hover': {
      color: theme.palette.secondary,
    },
    '&:active': {
      color: theme.palette.secondary,
    },
  },
  error: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 18,
  },
  searchBoxes: {
    flexDirection: 'row',
    display: 'flex',
    marginTop: '1rem',
  },
  searchBox: {},
  middleOr: {
    paddingLeft: 20,
    paddingRight: 20,
    alignSelf: 'center',
  },
  searching: {
    display: 'flex',
    flex: '0 1 33%',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  progressText: {
    marginBottom: '0.5rem',
  },
});

class PartnerLookup extends Component {
  state = {
    supplierId: '',
    valid: false,
  };
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);

    this.state = {
      supplierId: '',
      valid: false,
    };
  }

  componentWillMount() {
    this.props.checkReporting();
  }
  componentWillReceiveProps(nextProps) {
    this.props.checkCurrentUserToken();
    // Submit has been pressed, redux has populated the single supplier in global
    // state.
    if (nextProps.singleSupplier) {
      const { id } = nextProps.singleSupplier;
      this.props.history.push(`/reports/${id}`);
    }
  }
  submit(e) {
    e.preventDefault();
    if (!this.props.valid) {
      e.preventDefault();
      return 0;
    }

    const { supplierId } = this.state;
    const { user } = this.props;
    e.preventDefault();
    const { searchType } = this.props;
    const parm = {};
    parm[searchType] = this.props.keepState[searchType];

    this.props.searchVendors(parm);
  }

  getError() {
    return !this.props.error ? null : (
      <Alert variant='error' message={this.props.error} />
    );
  }

  getProgress() {
    let txt = 'Searching...';
    const { loading, keepState, searchType, classes } = this.props;

    if (loading) {
      const searchValue = keepState[searchType];

      switch (searchType) {
        case 'vendorName':
          txt = 'Searching suppliers with names like';
          break;
        case 'vendorNumber':
          txt = 'Searching supplier number';
          break;
      }

      return (
        <div>
          <Typography variant='subtitle' className={classes.progressText}>
            {txt}: <b>{searchValue}</b>
          </Typography>
          <div>
            <LinearProgress data-test-id='progress-indicator' />{' '}
          </div>
        </div>
      );
    }
    return null;
  }
  renderForm(props) {
    const { classes, history, reportsAvailable } = props;
    return (
      <div className={classes.searchBoxes}>
        <div className={classes.searchBox} style={{ alignItems: 'flex-start' }}>
          <SearchBox
            history={history}
            searchType='vendorName'
            label='Supplier Name'
            activeSearchType={this.props.searchType}
          />
        </div>
        <div className={classes.middleOr}>
          <Typography>- OR -</Typography>
        </div>
        <div className={classes.searchBox} style={{ alignItems: 'flex-start' }}>
          <SearchBox
            className={classes.searchBox}
            history={history}
            searchType='vendorNumber'
            label='Supplier Number'
            activeSearchType={this.props.searchType}
          />
        </div>
        <div
          className={classes.searching}
          style={{ alignItems: 'flex-start', paddingTop: '0.75em' }}
        >
          <Button
            variant='raised'
            type='submit'
            className={classes.cmdButton}
            color='primary'
            disabled={!this.props.valid}
            onClick={this.submit}
          >
            <i className='fa fa-search' aria-hidden='true'></i>Search
          </Button>

          <Button
            variant='raised'
            className={classes.cmdButton}
            color='secondary'
            onClick={() => {
              this.props.clearSearch();
            }}
          >
            <i
              className='fa fa-refresh'
              aria-hidden='true'
              title='Reset'
              alt='Clear Form'
            ></i>
          </Button>
        </div>
      </div>
    );
  }

  renderUnavailable(classes) {
    return (
      <div>
        <Alert
          variant='warning'
          message={
            'Supplier Reports are unavailable at this time since we are still processing last weeks data. Please check back after later to generate reports.'
          }
        />
      </div>
    );
  }
  render() {
    const { classes, history, reportsAvailable } = this.props;

    return (
      <div>
        <div className={classes.top}>
          <Alert
            variant='info'
            message={
              <span>
                <b>New Report Alert!</b> Sales & Inventory by VPN/Color is now
                available with PDF download!
              </span>
            }
          />
          <form onSubmit={this.submit}>
            <Typography variant='title' className={classes.heading}>
              Supplier Search
            </Typography>
            {this.getProgress()}
            {reportsAvailable
              ? this.renderForm(this.props)
              : this.renderUnavailable(this.props)}
            <div className={classes.error}>{this.getError()}</div>
            {this.props.results ? <SearchResults history={history} /> : null}
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { error, loading, searchType, keepState, valid, results, complete } =
    state.search;
  const { reportsAvailable } = state.suppliers;
  return {
    user: state.auth.user,
    error,
    loading,
    searchType,
    keepState,
    valid,
    results,
    complete,
    reportsAvailable,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    searchVendors,
    clearSearch,
    checkCurrentUserToken,
    checkReporting,
  })
)(PartnerLookup);
