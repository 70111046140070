import * as types from '../actions/types';
import 'isomorphic-fetch';

const DEFAULT_CONTENT = '';

const initialState = {
  vendorName: null,
  vendorNumber: null,
  loading: false,
  results: [],
  error: null,
  keepState: {},
  valid: false,
  complete: false,
};

const assign = (state, newVars) => {
  return Object.assign({}, state, newVars);
};

export default function content(state = initialState, action = {}) {
  switch (action.type) {
    case types.SEARCH_REQUEST:
      return assign(state, {
        loading: true,
        keepState: action.keepState,
        error: null,
      });

    case types.SEARCH_SUCCESS: {
      return assign(state, {
        loading: false,
        results: action.results,
        complete: true,
        valid: true,
      });
    }

    case types.SEARCH_ERROR:
      return assign(state, {
        loading: false,
        results: null,
        error: action.error,
        valid: true,
      });

    case types.SEARCH_CHANGE_FOCUS: {
      return assign(state, {
        keepState: action.keepState,
        searchType: action.searchType,
      });
    }

    case types.SEARCH_VALID: {
      return assign(state, { valid: action.valid });
    }

    case types.SEARCH_CLEAR: {
      return assign(state, {
        valid: false,
        searchType: 'vendorName',
        results: [],
        complete: false,
        keepState: { vendorName: '', vendorNumber: '' },
      });
    }

    default:
      return state;
  }
}
