import * as types from '../actions/types';
import reports from '../utils/fakes/reports';
import * as _ from 'lodash';

const filteredReports = reports.reports.filter((report) => !report.hidden);

const INITIAL_STATE = {
  suppliers: [],
  singleSupplier: null,
  singleError: null,
  singleComplete: false,
  reports: { ...reports, reports: filteredReports },
  reportsAvailable: true,
  query: { weeks: '1' },
  subscriptionTypes: [
    {
      id: 1,
      name: 'Supplier Reports',
      description: 'Supplier Reports supplied through Vendor Portal',
      code: 'SAI',
    },
  ],
  supplierDetail: null,
  subscriptionDetail: null,
  extractError: {},
};
const resetReportState = (state) => {
  delete state.error;

  for (const key in state) {
    if (state.reportLoadingStatus) {
      for (const k in state.reportLoadingStatus) {
        if (_.startsWith(k, '/reporting')) {
          delete state.reportLoadingStatus[k];
        }
      }
    }
  }
};
//
// export const REPORT_STATUS_REQUEST = "REPORT_STATUS_REQUEST";
export default function suppliers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SUPPLIERS_REQUEST:
      return Object.assign({}, state, {
        loadingSuppliers: true,

        suppliers: [],
      });
    case types.SET_SUPPLIER: {
      resetReportState(state);

      return Object.assign({}, state, {
        current: action.current,
      });
    }

    case types.SUPPLIERS_SUCCESS:
      return Object.assign({}, state, {
        loadingSuppliers: false,
        suppliers: action.suppliers,
        reports: reports,
      });
    case types.SUPPLIERS_FAILURE:
      return Object.assign({}, state, {
        loadingSuppliers: false,
        suppliers: [],
        error: true,
      });
    case types.SET_REPORTS_PARAMS:
      return Object.assign({}, state, {
        query: action.query,
        report: action.report,
      });
    case types.GET_REPORT_REQUEST: {
      resetReportState(state);

      return Object.assign({}, state, {
        success: action.success,
        reportLoadingStatus: { [action.report.urlFragment]: true },
      });
    }
    case types.GET_REPORT_ERROR:
      return Object.assign({}, state, {
        success: action.success,
        error: action.error,
        reportLoadingStatus: { [action.report.urlFragment]: action.error },
      });
    case types.GET_REPORT_SUCCESS:
      return Object.assign({}, state, {
        success: action.success,
        reportLoadingStatus: { [action.report.urlFragment]: false },
      });
    case types.EMPLOYEE_GO_SUPPLIER:
      return Object.assign({}, state, {
        current: action.current,
      });
    case types.SINGLE_SUPPLIER_REQUEST: {
      resetReportState(state);
      return Object.assign({}, state, {
        loadingSingle: true,
        singleComplete: false,
      });
    }

    case types.SINGLE_SUPPLIER_SUCCESS:
      return Object.assign({}, state, {
        loadingSingle: false,
        singleSupplier: action.payload,
        singleComplete: true,
        query: { weeks: 1 },
      });
    case types.SINGLE_SUPPLIER_FAILURE:
      return Object.assign({}, state, {
        loadingSingle: false,
        singleSupplier: null,
        singleError: action.payload,
        singleComplete: true,
      });
    case types.EXTRACT_ERROR:
      return Object.assign({}, state, {
        extractError: { [action.code]: action.error },
      });
    case types.REPORT_STATUS_REQUEST:
      return Object.assign({}, state, { reportsAvailable: true });
    case types.REPORT_STATUS_SUCCESS:
      return Object.assign({}, state, { reportsAvailable: true });
    case types.REPORT_STATUS_ERROR:
      return Object.assign({}, state, { reportsAvailable: action.available });

    default:
      return state;
  }
}
