import React, { Component } from 'react';

import { connect } from 'react-redux';
import { goHome } from '../../actions/auth';
import {
  getSubscriptionTypes,
  extractSubscriptions,
} from '../../actions/subscriptions';
import { withStyles } from 'material-ui/styles';

import { compose } from 'redux';

import './subscriptions.css';
import Button from 'material-ui/Button';
import ExpansionPanel from 'material-ui/ExpansionPanel';
import ExpansionPanelSummary from 'material-ui/ExpansionPanel/ExpansionPanelSummary';
import Typography from 'material-ui/Typography/Typography';
import ExpandMoreIcon from 'material-ui-icons/ExpandMore';
import Alert from '../../components/generic/Alert';

import ExpansionPanelDetails from 'material-ui/ExpansionPanel/ExpansionPanelDetails';
import LinearProgress from 'material-ui/Progress/LinearProgress';

const styles = (theme) => ({
  expansion: {
    flexDirection: 'column',
  },
  alert: {
    marginLeft: 10,
  },
  cmds: {
    display: 'flex',
    alignContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
  },
  cmdButton: {
    'flex': 1,
    'margin': 10,
    '&:hover': {
      color: theme.palette.secondary,
    },
    '&:active': {
      color: theme.palette.secondary,
    },
  },
  defaultList: {
    listStyleType: 'none',
    width: 600,
    padding: 0,
  },
  heading: {
    fontSize: '1.3em',
    marginBottom: '1.0em',
  },
  progressText: {
    marginBottom: '0.5rem',
  },
});

class SubscriptionsLanding extends Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {
    const { user } = this.props;
    if (!user.isSuperAdmin()) {
      this.props.goHome();
    }
    this.props.getSubscriptionTypes();
  }
  componentWillReceiveProps(nextProps) {
    nextProps.subscriptionTypes.map((st) => {
      const stateob = { open: false };
      this.setState({ [st.code]: stateob });
    });
  }

  renderSubType(subscriptionType, i) {
    const { extractError, classes, extractLoading } = this.props;
    let error = null;

    if (extractError) {
      const errorMessage = extractError[subscriptionType.code];

      error = errorMessage ? (
        <Alert variant='error' message={errorMessage} />
      ) : null;
    }

    return (
      <li key={subscriptionType.id} className={classes.root} id={i}>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='subheading'>
              {subscriptionType.description}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ flexDirection: 'column', flex: 1 }}>
              {error}
              {extractLoading && extractLoading[subscriptionType.code] ? (
                <div>
                  <Typography>
                    Getting {subscriptionType.name} subscriptions ...
                  </Typography>{' '}
                  <LinearProgress data-test-id='progress-indicator' />
                </div>
              ) : null}
              <div className={classes.cmds}>
                <Button
                  variant='raised'
                  type='submit'
                  className={classes.cmdButton}
                  color='primary'
                  href={'/#/subscriptions/manage/' + subscriptionType.id}
                >
                  <i className='fa fa-database' aria-hidden='true'></i>
                  &nbsp;Manage
                </Button>
                <Button
                  variant='raised'
                  className={classes.cmdButton}
                  color='secondary'
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.extractSubscriptions({ subscriptionType });
                  }}
                >
                  <i className='fa fa-file-o'></i>&nbsp;Export
                </Button>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </li>
    );
  }

  render() {
    const { extractError, classes, subscriptionTypes } = this.props;

    const content = subscriptionTypes
      ? this.props.subscriptionTypes.map((st, i) => {
          return this.renderSubType(st);
        })
      : [];

    const loading = (
      <div>
        <Typography variant='subtitle' className={classes.progressText}>
          Loading: <b>Subscription Types</b>
        </Typography>
        <div>
          <LinearProgress data-test-id='progress-indicator' />{' '}
        </div>
      </div>
    );

    return (
      <div className=''>
        <ul className={classes.defaultList}>
          <Typography variant='title' className={classes.heading}>
            Subscription Types
          </Typography>
          {this.props.loadingSubscriptionTypes ? loading : content}
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const {
    subscriptionTypes,
    loadingSubscriptionTypes,
    extractError,
    extractLoading,
  } = state.subscriptions;

  return {
    user,
    subscriptionTypes,
    loadingSubscriptionTypes,
    extractError,
    extractLoading,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    goHome,
    getSubscriptionTypes,
    extractSubscriptions,
  })
)(SubscriptionsLanding);
