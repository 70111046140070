import { callApi, headers, downloadFromApi } from '../utils/apiUtils';
import currentConfig from '../configuration';

import _ from 'lodash';
import * as types from './types';
import moment from 'moment';

export const selectSubscriptionDetail = ({
  vendorNumber,
  subscriptionTypeId,
}) => {
  return (dispatch) => {
    const config = {
      method: 'get',
      headers: headers(),
    };
    const req = () => {
      return {
        type: types.SELECT_SUBSCRIPTION_DETAIL_REQUEST,
        vendorNumber,
        subscriptionTypeId,
      };
    };
    const success = (subscriptions) => {
      if (subscriptions && subscriptions.length) {
        return {
          type: types.SELECT_SUBSCRIPTION_DETAIL_SUCCESS,
          payload: subscriptions[0],
        };
      } else {
        return {
          type: types.SELECT_SUBSCRIPTION_DETAIL_SUCCESS,
          payload: {
            id: 0,
            subscriptionType: subscriptionTypeId,
            vendorNumber,
            status: 'INACTIVE',
            feeExempt: false,
            startDate: null,
            endDate: null,
          },
        };
      }
    };

    const error = (error) => {
      const { message } = error;

      return {
        type: types.SELECT_SUBSCRIPTION_DETAIL_ERROR,
        error: message || "We're sorry, an error occurred.",
      };
    };

    const url = `${currentConfig.subscriptionsUrl}/subscriptions/search-by-criteria?vendorNumber=${vendorNumber}&subscriptionTypeId=${subscriptionTypeId}`;

    dispatch(req());

    return dispatch(callApi(url, config, () => {}, success, error));
  };
};

export const getSubscriptionTypes = () => {
  return (dispatch) => {
    const config = {
      method: 'get',
      headers: headers(),
    };
    const req = () => {
      return {
        type: types.GET_SUBSCRIPTION_TYPES_REQUEST,
      };
    };
    const success = (subscriptionTypes) => {
      return {
        type: types.GET_SUBSCRIPTION_TYPES_SUCCESS,
        payload: subscriptionTypes,
      };
    };

    const error = (error) => {
      const { message } = error;

      return {
        type: types.UPSERT_SUBSCRIPTION_ERROR,
        error: message || "We're sorry, an error occurred.",
      };
    };

    const url = `${currentConfig.subscriptionsUrl}/subscriptions/types`;

    dispatch(req());

    return dispatch(callApi(url, config, () => {}, success, error));
  };
};

const validateSubscription = (subscription) => {
  let error = null;

  if (subscription.status === 'ACTIVE' && !subscription.startDate) {
    error = { startDate: 'Start date is required' };
  }

  if (subscription.status == 'INACTIVE' && !subscription.endDate) {
    error = Object.assign(error || {}, { endDate: 'End date is required' });
  }

  return error;
};

export const upsertSubscription = (subscription) => {
  const failedValidation = validateSubscription(subscription);

  return (dispatch) => {
    if (failedValidation !== null) {
      return dispatch({
        type: types.UPSERT_SUBSCRIPTION_ERROR,
        error: failedValidation,
      });
    }

    const config = {
      method: 'put',
      headers: headers(),
    };

    if (!subscription.id) {
      config.method = 'post';
      delete subscription.id;
    }

    config.body = JSON.stringify(subscription);

    const req = () => {
      return {
        type: types.UPSERT_SUBSCRIPTION_REQUEST,
      };
    };
    const success = (subscription) => {
      return {
        type: types.UPSERT_SUBSCRIPTION_SUCCESS,
        payload: subscription,
      };
    };

    const error = (error) => {
      const { message } = error;

      return {
        type: types.UPSERT_SUBSCRIPTION_ERROR,
        error: { message: "We're sorry, an error occurred." },
      };
    };

    const url = `${currentConfig.subscriptionsUrl}/subscriptions`;

    dispatch(req());

    return dispatch(callApi(url, config, () => {}, success, error));
  };
};

export const clearSubscriptionDetail = () => {
  return {
    type: types.SUBSCRIPTION_DETAIL_RESET,
  };
};

const extractSuccess = ({ code }) => {
  return {
    type: types.EXTRACT_SUCCESS,
    code,
  };
};

const csvFileName = ({ subscriptionType, dateName }) => {
  const sname = subscriptionType.name.replace(/\s+/g, '-').toLowerCase();
  const fileName = `${sname}-subs-${dateName}.csv`;

  return fileName;
};

const SUPPRESS_CSV_VALUES = ['subscriptionType', 'lastUpdatedBy'];

const formatCsv = ({ key, value }) => {
  if (value === null || value === 'null') {
    return '';
  }
  switch (key) {
    case 'feeExempt':
      return value === 'true' ? 'Yes' : 'No';
    case 'startDate':
    case 'endDate':
      {
        const fdate = moment(parseInt(value)).format('MM/DD/YYYY');
        return fdate;
      }
      break;
    case 'vendorStatus':
      {
        return _.trim(JSON.parse(value)).toUpperCase() == 'A'
          ? 'ACTIVE'
          : 'INACTIVE';
      }
      break;
    case 'payToStatus':
      {
        return _.trim(JSON.parse(value)).toUpperCase() == 'A'
          ? 'ACTIVE'
          : 'INACTIVE';
      }
      break;
    case 'comment': {
      return `${_.trim(value).replace(/\\n/g, '\r\n')}`;
    }
    default:
      return value;
  }
};
const xform = {
  id: 'Subscription ID',
  payToNumber: 'Vendor',
  payToName: 'Vendor Name',
  payToStatus: 'Vendor Status',
  vendorNumber: 'Supplier',
  supplierName: 'Supplier Name',
  vendorStatus: 'Supplier Status',
  status: 'Subscription Status',
  feeExempt: 'Fee Exempt',
  startDate: 'Start Date',
  endDate: 'End Date',
  comment: 'Comments',
};
const formatHeader = (columns) => {
  const dataHeaders = _.filter(
    columns,
    (c) => !_.includes(SUPPRESS_CSV_VALUES, c)
  );

  return _.keys(xform).map((h) => {
    return xform[h] || h;
  });
};

const jsonToCsv = (jsonData) => {
  const items = jsonData;
  const header = _.keys(xform); // _.filter(Object.keys(items[0]), c => { return !_.includes(SUPPRESS_CSV_VALUES, c) } );

  const fheaders = formatHeader(Object.keys(items[0]));

  let csv = items.map((row) =>
    header
      .map((fieldName) => {
        return formatCsv({
          key: fieldName,
          value: JSON.stringify(row[fieldName]),
        });
      })
      .join(',')
  );

  csv.unshift(fheaders.join(','));
  csv = csv.join('\r\n');

  return csv;
};

export function extractSubscriptions({ subscriptionType }) {
  return (dispatch) => {
    const url = `${currentConfig.subscriptionsUrl}/subscriptions/${subscriptionType.id}/extract`;
    // Example: SalesAndInventoryByDeptLocations_103_2017-10-17_19.56.51.pdf
    const dateName = moment().format('YYYY-MM-DD_HH.mm.ss');
    const fileName = csvFileName({ subscriptionType, dateName });

    const h = headers();
    h['Content-Type'] = 'application/x-www-form-urlencoded';
    delete h.Accept;

    const config = {
      method: 'GET',
      headers: h,
    };

    const err = function (e) {
      const { message } = e;

      return dispatch(() => {
        return {
          type: types.EXTRACT_ERROR,
          code: subscriptionType.code,
          error: message || "We're sorry, an error occurred",
        };
      });
    };

    return dispatch(
      downloadFromApi(
        fileName,
        url,
        config,
        () => ({
          type: types.EXTRACT_REQUEST,
          fileName: fileName,
          date: dateName,
          code: subscriptionType.code,
        }),
        () => ({
          type: types.EXTRACT_SUCCESS,
          code: subscriptionType.code,
        }),
        err,
        jsonToCsv
      )
    );
  };
}
