import { callApi } from '../utils/apiUtils';
import * as types from './types';
import currentConfig from '../configuration';

const searchVendorRequest = ({ vendorNumber, vendorName }) => {
  const keepState = { vendorNumber, vendorName };
  return {
    type: types.SEARCH_REQUEST,
    keepState,
  };
};

const searchVendorSuccess = ({ results }) => {
  return {
    type: types.SEARCH_SUCCESS,
    results,
  };
};

const searchVendorError = (error) => {
  return {
    type: types.SEARCH_ERROR,
    error: 'Sorry, an error occurred while searching.',
  };
};

export const searchVendors = ({ vendorNumber, vendorName }) => {
  return (dispatch) => {
    dispatch(searchVendorRequest({ vendorNumber, vendorName }));

    const config = {
      method: 'POST',
      body: JSON.stringify({ vendorNumber, vendorName }),
    };

    return dispatch(
      callApi(
        `${currentConfig.appUrl}/api/supplierSearch`,
        config,
        () => {},
        searchVendorSuccess,
        searchVendorError
      )
    );
  };
};

export const changeSearchFocus = (searchType, inputText) => {
  return (dispatch, getState) => {
    const state = getState();

    const { keepState } = state.search;
    keepState[searchType] = inputText;

    return dispatch({
      type: types.SEARCH_CHANGE_FOCUS,
      searchType,
      keepState,
    });
  };
};

export const changeSearchValidity = ({ valid }) => {
  return {
    type: types.SEARCH_VALID,
    valid,
  };
};

export const clearSearch = () => {
  return {
    type: types.SEARCH_CLEAR,
  };
};
