import _ from 'lodash';

export const NORD_ADMIN_SID =
  'S-1-5-21-118107795-1152602752-1039283242-1638376';
export const NORD_USER_SID = 'S-1-5-21-118107795-1152602752-1039283242-1638375';
export const PCP_ADMIN = 'pcp_admin';
export const PCP_USER = 'pcp_user';
export const VALID_USER_ROLES = [PCP_ADMIN, PCP_USER];

export default class PCUser {
  canAccessSupplier = (supplierId) => {
    if (this.isNordstromEmployee) {
      return (
        _.includes(this.groups, NORD_ADMIN_SID) ||
        _.includes(this.groups, NORD_USER_SID)
      );
    }

    const canAccess =
      this.hasRole(supplierId, PCP_USER) || this.hasRole(supplierId, PCP_ADMIN);

    return canAccess;
  };

  getAccountUrl = (adminAccountUrl, profileAccountUrl) => {
    if (this.isNordstromEmployee) {
      if (_.includes(this.groups, NORD_ADMIN_SID)) {
        return adminAccountUrl;
      } else {
        return null;
      }
    } else {
      const roleData = this.getAppRoleData();

      const roles = _(roleData).map('roles').flatten().valueOf();

      const isVendorAdmin = _.includes(roles, PCP_ADMIN);

      return isVendorAdmin ? adminAccountUrl : profileAccountUrl;
    }
  };

  accountEnabled = () => {
    // Check the user's SIDs and make sure they are in one of the right groups.

    if (this.isNordstromEmployee)
      return _.includes(this.groups, NORD_ADMIN_SID);

    // Gather all the roles of the user
    const roleData = this.getAppRoleData();

    // roles = ["pcp_admin", "pcp_admin", "pcp_user]
    const roles = _(roleData).map('roles').flatten().valueOf();

    const ok = _.includes(roles, PCP_ADMIN);
    return ok; // If the user is administrator of any vendors, return true.
  };
  /*
        If the user has pending actions they need to perform on their profile (eg. recovery email, security questions etc).
     */
  hasPendingProfileRequirements = () => {
    if (this.isNordstromEmployee) return false;
    return !this.hasRecoveryQuestion;
  };

  canAdminSupplier = (supplierId) => {
    if (this.isNordstromEmployee) {
      return _.includes(this.groups, NORD_ADMIN_SID);
    }

    if (!this.canAccessSupplier(supplierId)) return false;

    return this.hasRole(supplierId, PCP_ADMIN);
  };

  // Laura sent me this: for dep loc report supplier / dept combos: 5090168 / 558; 5106421 / 109; 5096077 / 567; 5119507 / 174
  getSupplierIds = () => {
    const parterIds = _.map(this.groups, (g) => {
      if (g.partner) {
        return g.partner.id;
      } else {
        return null;
      }
    });

    return _.uniq(parterIds.filter((v) => !!v === true));
  };

  // returns an array of objects looking like [ { partner: { id: 12345, name: "Nike Shoes" }, roles: ["pcp_admin", "pcp_user"] } ]
  getAppRoleData = () => {
    // Account for the case where a user has not been associated to any groups.
    if (!this.groups) return [];

    const rg = this.groups.map((group, i) => {
      if (_.isObject(group.partner)) {
        const roles = _(group.roles)
          .flatten()
          .map((p) => p.name)
          .valueOf();

        const { partner } = group;

        return { partner, roles };
      }

      return null;
    });

    return rg.filter((v) => !!v === true);
  };

  hasRole = (vendorNumber, roleName) => {
    if (!vendorNumber) {
      throw new Error('vendorNumber not supplied to hasRole()');
    }
    if (!roleName) {
      throw new Error('roleName not supplied to hasRole()');
    }
    if (!_.includes(VALID_USER_ROLES, roleName)) {
      throw new Error(roleName + ' is not a valid roleName');
    }

    const appRoles = this.getAppRoleData();

    const matchingRoles = _.find(appRoles, (pr) => {
      if (pr.partner.id == vendorNumber) {
        if (_.includes(pr.roles, roleName)) {
          return pr;
        }
      }
    });

    return !!matchingRoles;
  };

  isContentEditor = () => {
    if (this.isNordstromEmployee) {
      return _.includes(this.groups, NORD_ADMIN_SID);
    }

    return false;
  };
  isSuperAdmin = () => {
    if (this.isNordstromEmployee) {
      return _.includes(this.groups, NORD_ADMIN_SID);
    }

    return false;
  };

  constructor(nuser) {
    this.isNordstromEmployee = nuser.isNordstromEmployee;

    this.accessToken = nuser.accessToken;

    const { userInfo } = nuser;

    if (this.isNordstromEmployee) {
      this.sub = nuser.userInfo.sub;
      this.displayName =
        nuser.userInfo.first_name && nuser.userInfo.last_name
          ? `${nuser.userInfo.first_name} ${nuser.userInfo.last_name}`
          : nuser.userInfo.name;
      this.user = nuser.userInfo.user;
      this.groups = nuser.userInfo.group_sids;
    } else {
      const profile = userInfo.profile;
      this.groups = userInfo.groups;
      this.sub = userInfo.id;
      this.displayName = profile.name;
      this.user = profile.email;
      this.hasRecoveryQuestion = userInfo.hasRecoveryQuestion || false;
    }
  }
}
