import * as types from '../actions/types';

import { loadUserProfile, isTokenExpired } from '../utils/apiUtils';

import 'isomorphic-fetch';

const initialState = {
  user: null,
  password: null,
  userRole: null,
  loggingIn: false,
  loggingOut: false,
  loginError: null,
  expired: false,
  error: null,
  loginUrl: '',
  logoutUrl: '',
};

function initializeState() {
  const pcUser = loadUserProfile();

  return Object.assign({}, initialState, {
    user: pcUser,
    expired: isTokenExpired(),
  });
}
const assign = (state, newVars) => {
  return Object.assign({}, state, newVars);
};
export default function auth(state = initializeState(), action = {}) {
  switch (action.type) {
    case types.GET_LOGIN_URL_REQUEST:
      return assign(state, { loginUrl: '', gettingLoginUrl: true });

    case types.GET_LOGIN_URL_SUCCESS:
      return assign(state, {
        loginUrl: action.loginUrl,
        gettingLoginUrl: false,
      });

    case types.GET_LOGIN_URL_FAILURE:
      return assign(state, { loginUrl: '', gettingLoginUrl: false });

    case types.GET_LOGOUT_URL_SUCCESS:
      return assign(state, { logoutUrl: action.logoutUrl });

    case types.GET_LOGOUT_URL_FAILURE:
      return assign(state, { logoutUrl: action.logoutUrl });

    case types.KEY_EXCHANGE_REQUEST:
      return assign(state, { loggingIn: true });

    case types.KEY_EXCHANGE_SUCCESS:
      return assign(state, { loggingIn: false, user: action.user });

    case types.KEY_EXCHANGE_FAILURE:
      return assign(state, {
        loggingIn: false,
        user: action.user,
        error: action.error,
      });

    case types.CHECK_TOKEN:
      return assign(state, { expired: action.expired });

    case types.LOGOUT_REQUEST:
      return {
        ...state,
        loggingOut: true,
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        loggingOut: false,
        user: null,
        userRole: null,
        loginError: null,
        loggingIn: false,
        expired: false,
      };
    case types.LOGOUT_FAILURE:
      return {
        ...state,
        loggingOut: false,
        logoutError: action.error,
      };
    default:
      return state;
  }
}
