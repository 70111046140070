import React, { Component } from 'react';
import { NavHashLink as Link } from 'react-router-hash-link';
import login1 from './img/login1.png';
import login2 from './img/login2.png';
import login3 from './img/login3.png';
import login4 from './img/login4.png';
import admin1 from './img/admin1.png';
import admin2 from './img/admin2.png';
import admin3 from './img/admin3.png';
import admin4 from './img/admin4.png';
import metrics1 from './img/metrics1.png';
import cpass1 from './img/cpass1.png';
import cpass2 from './img/cpass2.png';
import cpass3 from './img/cpass3.png';
import sq1 from './img/sq1.png';
import fpss1 from './img/fpss1.png';
import fpss2 from './img/fpss2.png';
import fpss3 from './img/fpss3.png';
import fpa1 from './img/fpa1.png';

import './doc.css';
import withStyles from 'material-ui/styles/withStyles';

const styles = (theme) => ({
  root: {
    '& .images': {
      'textAlign': 'center',
      'alignContent': 'center',
      'display': 'flex',
      'flexDirection': 'column',
      '& img': {
        display: 'flex',
        alignSelf: 'center',
        maxWidth: '100%',
        marginBottom: '1em',
        [theme.breakpoints.between('xs', 'sm')]: {
          maxWidth: '80%',
          marginRight: '5%',
          marginLeft: '5%',
        },
        /*
                align-content: center;
  display: flex;
  flex-direction: column;
              **/
      },
    },
    '& td': {
      border: '1px solid #dddddd',
      textAlign: 'left',
      padding: '8px',
    },
    // "& th": {
    //   border: "1px solid #dddddd",
    //   textAlign: "left",
    //   padding: "8px",
    // },
  },
});

class Usage extends Component {
  render() {
    const { classes } = this.props;

    const backToTop = (
      <p>
        <b>
          <Link to='/usage/#top'>Back to top</Link>
        </b>
      </p>
    );

    return (
      <div className={classes.root}>
        <div className='col-md-12'>
          <div className='inner'>
            <h1>
              <a id='top'>User Guide</a>
            </h1>
            <p>
              <b>
                <Link to='/usage/#gettingstarted'>Getting Started</Link>
              </b>
            </p>
            <p>
              <b>
                <Link to='/usage/#signup'>Sign Up</Link>
              </b>
            </p>
            <p>
              <b>
                <Link to='/usage/#login'>Log In</Link>
              </b>
            </p>
            <p>
              <b>
                <Link to='/usage/#admin'>User Administration</Link>
              </b>
            </p>

            <p>
              <b>
                <Link to='/usage/#password'>Change Password - Self Serve </Link>
              </b>
            </p>
            <p>
              <b>
                <Link to='/usage/#securityQuestion'>
                  Change Security Question
                </Link>
              </b>
            </p>
            <p>
              <b>
                <Link to='/usage/#fpSs'>Forgot Password - Self Serve</Link>
              </b>
            </p>
            <p>
              <b>
                <Link to='/usage/#fpadmin'>
                  Forgot Password - Administrator
                </Link>
              </b>
            </p>
            <p>
              <b>
                <Link to='/usage/#metrics'>Report Metrics</Link>
              </b>
            </p>
            <h3>
              <a id='gettingstarted'></a>Getting Started
            </h3>
            <div>
              <ol className='answerlist'>
                <legend>
                  <p>
                    Supplier Reports is the place to pull Sales and Inventory
                    Reporting from Nordstrom and is located at the web address{' '}
                    <a href='https://partner.nordstrom.com'>
                      https://partner.nordstrom.com
                    </a>
                    . It can be accessed by registering following the steps
                    detailed in the <Link to='/usage/#signup'>Sign Up</Link>{' '}
                    section later in this document. Nordstrom encourages all
                    vendors to register for Supplier Reports as it enables
                    self-serve analysis.
                  </p>
                  <p>
                    <b>Report Parameters</b>
                  </p>
                  <ul className='sublist'>
                    <legend>By Department and Number of Weeks</legend>
                    <ul>
                      <li>One department at a time</li>
                      <li>Up to 26 weeks</li>
                    </ul>
                  </ul>
                  <br />
                  <legend>
                    <b>There are 7 avaliable Reports</b>
                  </legend>
                  <ol>
                    <li>Sales and Inventory by Dept-Locations</li>
                    <li>Sales and Inventory by Dept-VPN</li>
                    <li>
                      Sales and Inventory by Dept-VPN, by BUsiness
                      Unit-Group-Region
                    </li>
                    <li>Sales and Inventory by Dept-VPN, by Region-Location</li>
                    <li>Sales and Inventory by Dept-VPN/Color</li>
                    <li>Supplier Color Size Detail</li>
                    <li>TY-LY by Dept-Locations</li>
                  </ol>
                </legend>
                <br />
                <legend>
                  <b>Export Formats</b>
                  <legend>Reports are valiable in CSV format.</legend>
                </legend>
                <br />
                <legend>
                  <b>Report Availability</b>
                  <legend>
                    Reports are refreshed weekly on Sunday nights. The current
                    version of each report will be available on Monday mornings.
                  </legend>
                </legend>
                <br />
                <legend>
                  <b>Best Practice</b>
                  <legend>
                    Save weekly reports so that you may create rolling totals as
                    desired.
                  </legend>
                </legend>
              </ol>

              <Link className='back' to='/usage/#top'>
                Back to Top
              </Link>
            </div>
            <h3>
              <a id='signup'></a>Sign Up
            </h3>
            <div>
              <ul className='sublist'>
                <legend>Registering for Supplier Reports</legend>
                <li>
                  How do I register myself for the Supplier Reports reporting?
                  <p className='answer'>
                    Please contact{' '}
                    <a href='mailto:SupplierReports@nordstrom.com'>
                      SupplierReports@nordstrom.com
                    </a>{' '}
                    to request access to the sales and inventory reporting on
                    the Supplier Reports website.
                  </p>
                </li>
                <li>
                  What information do I need to register?
                  <p className='answer'>
                    You will need a valid e-mail address along with your
                    Nordstrom Supplier Name.
                  </p>
                </li>
                <li>
                  What will my e-mail address be used for?
                  <p className='answer'>
                    At the most basic level your email address is required to
                    gain access to the new Supplier Portal. It is only used to
                    contact you in regards to your account management.
                  </p>
                </li>
                <li>
                  Who is an Administrator and what purpose do they serve in the
                  Portal?
                  <p className='answer'>
                    Administrator is a feature of our Supplier Portal.
                  </p>
                  <ol className='roman'>
                    <legend>Here is some important information to note:</legend>
                    <li>
                      Every Pay-to-Vendor/Supplier company must designate at
                      least one individual within the Supplier/Vendor company as
                      an Administrator for their account with Nordstrom.
                    </li>
                    <li>
                      There can be more than one administrator for an account.
                      Best practice is to keep a minimum of 2 administrators per
                      Supplier/Vendor company.
                    </li>
                    <li>
                      Administrators can then login to the Portal so that they
                      may add/remove users for his/her account.
                    </li>
                    <li>
                      Registration is required at each individual supplier
                      level. This can be done by administrators.
                    </li>
                  </ol>
                </li>
              </ul>
            </div>
            {backToTop}
            <h3>
              <a id='login'></a>Log In
            </h3>
            <div>
              <ul>
                <legend>
                  Users can login using their email address to access the
                  Supplier Reports application to generate the sales and
                  inventory reports. Click on the “Log In” link available on the
                  below home page and provide your registered email address and
                  password.
                </legend>
              </ul>
              <div className='images'>
                <img src={login1} />
                <img src={login2} />
              </div>
              <p>
                Based on the user’s account access, the application will
                dynamically display all vendors the user has access to view. In
                the below example, the user has access to only 1 vendor.
              </p>
              <div className='images'>
                <img src={login3} />
                <img src={login4} />
              </div>
            </div>
            <h3>
              <a id='admin'></a>User Administration
            </h3>
            <div>
              <ul>
                <legend>
                  Administrators can login to the portal and click on the
                  ‘Account’ tab to administer their account.
                </legend>
              </ul>
              <div className='images'>
                <img src={admin1} />
              </div>
              <p>
                An administrator can view the list of all the Supplier Groups
                which he/she has access to.
              </p>
              <div className='images'>
                <img src={admin2} />
              </div>
              <p>
                An administrator can view the list of all the registered users
                under this section. The admin has the option to grant or revoke
                access to each user.
              </p>
              <div className='images'>
                <img src={admin3} />
              </div>
              <p>
                An administrator can add the new user using the “ADD USER”
                button and providing the required details.
              </p>
              <div className='images'>
                <img src={admin4} />
              </div>
            </div>
          </div>
          {backToTop}
          <h3>
            <a id='password'></a>Change Password - Self Serve
          </h3>
          <div>
            <p>
              You can change your password using the “Change password” link on
              the profile page within Accounts.
            </p>
            <b>To change your password from the profile page:</b>
            <br />
            <ul>
              <li>Click the "Account" link</li>
              <li>Select Profile link from the dropdown.</li>
              <li>On the profile page, click "Change password"</li>
              <li>
                Enter your current password, type your new password, then retype
                it to confirm
              </li>
            </ul>
            <div className='images'>
              <img src={cpass1} />
            </div>
            <div className='images'>
              <img src={cpass2} />
            </div>
            <div className='images' style={{ marginTop: '1em' }}>
              <img src={cpass3} />
            </div>
            {backToTop}
          </div>
          <div id='securityQuestion'>
            <h3>
              <a id='securityQuestionLink'></a>Change Security Question
            </h3>
            <ul>
              <li>Click the “Account” link on the home page</li>
              <li>Select Profile link from the dropdown</li>
              <li>On the profile page, click "Change Security Question"</li>
              <li>
                Enter your current password, type your new security question,
                and then type answer.
              </li>
            </ul>

            <div className='images'>
              <img src={sq1} />
            </div>
          </div>
          {backToTop}
          <div id='fpSs'>
            <h3>
              <a id='selfServe'></a>Forgot Password - Self Serve
            </h3>
            <p>
              If you need help resetting your password, we can help by sending
              you a link to reset it.
            </p>
            <ul>
              <li>Click on the “Forgot password?” link on the Sign In page</li>
            </ul>
            <div className='images'>
              <img src={fpss1} />
            </div>
            <ul>
              <li>Enter email address</li>
              <li>Click on "Reset password via email"</li>
              <li>
                Check your inbox for a password reset email. The reset link in
                the email is valid only for a limited time. After timeout, you
                must request another link.
              </li>
              <li>
                Click on the URL provided in the email and enter a new password.
              </li>
            </ul>

            <div className='images'>
              <img src={fpss2} />
            </div>

            <p>
              Note: You will have to enter answer to the security question and
              new password. If you don’t have a security question set, then
              please follow the steps mentioned in{' '}
              <b>"Forgot Password – Administrator"</b>.
            </p>
            <div className='images'>
              <img src={fpss3} />
            </div>

            {backToTop}
          </div>
          <div id='fpadmin'>
            <h3>
              <a id='fpadminLink'></a>Forgot Password - Administrator
            </h3>
            <p>
              Your Administrators can login to the Supplier Reports portal and
              click on the "Send change password mail" against your account to
              reset your password. You will get an email with the link to reset
              your password.{' '}
            </p>
            <div className='images'>
              <img
                src={fpa1}
                style={{
                  marginLeft: 70,
                  maxWidth: 700,
                  marginTop: '1em',
                  paddingBottom: 0,
                  marginBottom: '2em',
                }}
              />
            </div>
            <p>
              <b>
                I would like to change my email address and keep my password,
                who should I contact?
              </b>
            </p>
            <p>
              In the new Supplier Reports, your email address is used as a
              unique identifier to access your account. This information cannot
              be changed once registered. However, you may choose to register
              for the same supplier using another email address of yours. This
              will be considered as a new account registration.
            </p>
            {backToTop}
          </div>
          <div id='metrics'>
            <h3>
              <a id='metricsLink'></a>Report Notes and Metrics
            </h3>
            <div>
              <ul>
                <legend>
                  NOTE ON VIRTUAL STORES: Nordstrom offers the customer
                  convenient services for most items to “Ship to Store” and
                  “Return to Any Store”. Returns are routed to the appropriate
                  selling channel based on the current item price (i.e. Full
                  Price item returned at a Rack store is sent to a Full Price
                  location). Virtual Stores (VS in name) facilitate inventory
                  movement, maintain price integrity and all activity rolls up
                  to the Full Line Stores, according to their geographic
                  location. Sales, returns, and a point in time Ending On Hand
                  may be present in Virtual Stores.
                </legend>
              </ul>
            </div>
            {/* <div>
              <h4 style={{ textAlign: "center" }}>
                Here is the Virtual Store list as of 6/24/2021
              </h4>
              <table
                className={classes.root}
                style={{ marginLeft: "auto", marginRight: "auto" }}
              >
                <tr>
                  <th>Store #</th>
                  <th>Store Name</th>
                  <th>City</th>
                  <th>St/Prov</th>
                  <th>BU</th>
                  <th>Region</th>
                </tr>
                <tr>
                  <td>8</td>
                  <td>Vs Northgate RK</td>
                  <td>Seattle</td>
                  <td>WA</td>
                  <td>FULL LINE</td>
                  <td>NORTHWEST FLS</td>
                </tr>
                <tr>
                  <td>444</td>
                  <td>Vs Colonies Crossroads Rack</td>
                  <td>Upland</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>446</td>
                  <td>Vs Outlets at Orange Rack</td>
                  <td>Orange</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>447</td>
                  <td>Vs Culver City Rack</td>
                  <td>Culver City</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>448</td>
                  <td>Vs Chino Rack</td>
                  <td>Chino</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>451</td>
                  <td>Vs Town Center Rack</td>
                  <td>Palm Desert</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>452</td>
                  <td>Vs Hastings Village Rack</td>
                  <td>Pasadena</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>453</td>
                  <td>Vs Mountain Grove Rack</td>
                  <td>Redlands</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>454</td>
                  <td>Vs Burbank Empire Center Rack</td>
                  <td>Burbank</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>456</td>
                  <td>Vs Edinger Plaza Rack</td>
                  <td>Huntington Beach</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>457</td>
                  <td>Vs Riverside Plaza Rack</td>
                  <td>Riverside</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>458</td>
                  <td>Vs Topanga Rack</td>
                  <td>Woodland Hills</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>460</td>
                  <td>Vs West Covina Rack</td>
                  <td>West Covina</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>461</td>
                  <td>Vs Tustin Marketplace Rack</td>
                  <td>Tustin</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>462</td>
                  <td>Vs Oxnard Rack</td>
                  <td>Oxnard</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>463</td>
                  <td>Vs CA Long Beach by Airport RK</td>
                  <td>Long Beach</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>464</td>
                  <td>Vs Ontario Mills Rack</td>
                  <td>Ontario</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>465</td>
                  <td>Vs Marina Pacifica Rack</td>
                  <td>Long Beach</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>466</td>
                  <td>Vs Shops at Riverwalk Rack</td>
                  <td>Bakersfield</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>467</td>
                  <td>Vs Laguna Hills Rack</td>
                  <td>Laguna Hills</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>468</td>
                  <td>Vs Metro Pointe Rack</td>
                  <td>Costa Mesa</td>
                  <td>CA</td>
                  <td>FULL LINE</td>
                  <td>SOUTHERN CALIFORNIA FLS</td>
                </tr>
                <tr>
                  <td>508</td>
                  <td>Vs Manhasset Center Rack</td>
                  <td>Manhasset</td>
                  <td>NY</td>
                  <td>FULL LINE</td>
                  <td>NORTHEAST FLS</td>
                </tr>
                <tr>
                  <td>557</td>
                  <td>Vs Fulton Street Rack</td>
                  <td>Brooklyn</td>
                  <td>NY</td>
                  <td>FULL LINE</td>
                  <td>NORTHEAST FLS</td>
                </tr>
                <tr>
                  <td>564</td>
                  <td>Vs Huntington Shopping Cntr RK</td>
                  <td>Huntington Station</td>
                  <td>NY</td>
                  <td>FULL LINE</td>
                  <td>NORTHEAST FLS</td>
                </tr>
                <tr>
                  <td>565</td>
                  <td>Vs Staten Island Rack</td>
                  <td>Staten Island</td>
                  <td>NY</td>
                  <td>FULL LINE</td>
                  <td>NORTHEAST FLS</td>
                </tr>
                <tr>
                  <td>570</td>
                  <td>Vs the Gallery Rack</td>
                  <td>Garden City</td>
                  <td>NY</td>
                  <td>FULL LINE</td>
                  <td>NORTHEAST FLS</td>
                </tr>
                <tr>
                  <td>575</td>
                  <td>Vs Gateway Center Rack</td>
                  <td>Brooklyn</td>
                  <td>NY</td>
                  <td>FULL LINE</td>
                  <td>NORTHEAST FLS</td>
                </tr>
                <tr>
                  <td>611</td>
                  <td>Vs Bridgewater Chimney Rock RK</td>
                  <td>Bound Brook</td>
                  <td>NJ</td>
                  <td>FULL LINE</td>
                  <td>NORTHEAST FLS</td>
                </tr>
                <tr>
                  <td>616</td>
                  <td>Vs Livingston Center Rack</td>
                  <td>Livingston</td>
                  <td>NJ</td>
                  <td>FULL LINE</td>
                  <td>NORTHEAST FLS</td>
                </tr>
                <tr>
                  <td>617</td>
                  <td>Vs Rockaway Rack</td>
                  <td>Rockaway</td>
                  <td>NJ</td>
                  <td>FULL LINE</td>
                  <td>NORTHEAST FLS</td>
                </tr>
              </table>
              <br /> */}
            <br />
            <div className='images'>
              <img src={metrics1} />
            </div>
            {/* </div> */}
          </div>
        </div>
        {backToTop}
      </div>
    );
  }
}

export default withStyles(styles)(Usage);
