import * as React from 'react';
import {
  FeatureFlag as FeatureFlagType,
  Role,
  hasRole,
  isFeatureFlagEnabled,
} from '../../utils/featureFlag';

type FeatureFlagProps = {
  children: React.ReactNode;
  role?: Role;
  featureFlag?: FeatureFlagType;
};

export class FeatureFlag extends React.Component<FeatureFlagProps> {
  render(): React.ReactNode {
    if (this.props.featureFlag && !isFeatureFlagEnabled(this.props.featureFlag))
      return null;

    if (this.props.role && !hasRole(this.props.role)) return null;

    return this.props.children;
  }
}
