import React, { Component } from 'react';
import { connect } from 'react-redux';

import { goHome } from '../../actions/auth';

import {
  upsertSubscription,
  clearSubscriptionDetail,
} from '../../actions/subscriptions';
import * as _ from 'lodash';
import './subscriptions.css';
import moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import Dialog from 'material-ui/Dialog/Dialog';
import DialogTitle from 'material-ui/Dialog/DialogTitle';
import DialogContent from 'material-ui/Dialog/DialogContent';
import DialogActions from 'material-ui/Dialog/DialogActions';
import FormGroup from 'material-ui/Form/FormGroup';
import Typography from 'material-ui/Typography/Typography';
import withStyles from 'material-ui/styles/withStyles';
import FormControl from 'material-ui/Form/FormControl';
import FormControlLabel from 'material-ui/Form/FormControlLabel';
import FormLabel from 'material-ui/Form/FormLabel';
import RadioGroup from 'material-ui/Radio/RadioGroup';
import { bindActionCreators, compose } from 'redux';
import Radio from 'material-ui/Radio/Radio';
import Button from 'material-ui/Button/Button';
import TextField from 'material-ui/TextField/TextField';
import Divider from 'material-ui/Divider/Divider';
import Alert from '../../components/generic/Alert';

momentLocalizer(moment);
const styles = (theme) => ({
  root: {},
  formControl: {
    width: '100%',
  },
  group: {
    margin: `${theme.spacing.unit}px 0`,
    justifyItems: 'space-around!important',
    display: 'flex',
    flexWrap: 'nowrap!important',
    flexDirection: 'row!important',
  },
  textField: {
    flex: 1,
    minHeight: '3em',
  },

  radio: {
    display: 'flex',
    flexDirection: 'row!important',
  },
  radioItem: {
    minWidth: 100,
  },
  formLabel: {
    alignSelf: 'center!important',
    flex: 1,
  },
  bottomButton: {
    maxWidth: 84,
    width: 84,
  },
});

class SubscriptionsDetail extends Component {
  constructor(props) {
    super(props);
    this.saveSubscription = this.saveSubscription.bind(this);
    this.handleClose = this.handleClose.bind(this);

    const { id, status, feeExempt, startDate, endDate, comment } =
      this.props.subscription;

    this.state = { id, status, feeExempt, startDate, endDate, comment };
  }

  componentWillReceiveProps(nextProps) {}

  componentWillMount() {
    const { user, currentSubscriptionTypeId, subscription } = this.props;
    if (!user.isSuperAdmin()) {
      this.props.goHome();
    }

    const { id, vendorNumber } = subscription;

    this.setState({
      subscriptionType: this.props.currentSubscriptionTypeId,
    });
  }

  changeComment(event) {
    const { value } = event.target;

    this.setState({ comment: value });
  }

  changeSubscriptionStatus(event) {
    const { value } = event.target;

    this.setState({ status: value });
  }

  changeFeeExemptStatus(event) {
    const { value } = event.target;

    this.setState({ feeExempt: value === 'true' });
  }

  saveSubscription() {
    const {
      id,
      status,
      feeExempt,
      subscriptionType,
      startDate,
      endDate,
      comment,
    } = this.state;
    const { vendorNumber } = this.props.subscription;
    const { user } = this.props;

    const stateSub = {
      id,
      vendorNumber,
      status,
      feeExempt,
      subscriptionType,
      startDate,
      endDate,
      comment,
    };
    stateSub.lastUpdatedBy = user.user.toLowerCase();

    if (startDate) {
      stateSub.startDate = moment(startDate).valueOf();
    } else {
      stateSub.startDate = null;
    }
    if (endDate) {
      stateSub.endDate = moment(endDate).valueOf();
    } else {
      stateSub.endDate = null;
    }

    if (stateSub.id === 0) {
      delete stateSub.id;
    }

    this.props.upsertSubscription(stateSub);
  }
  errorMessage() {
    const { error } = this.props;

    return (
      <FormGroup>
        <Alert variant='error' message={error} />
      </FormGroup>
    );
  }
  successMessage() {
    return (
      <FormGroup>
        <Alert variant='success' message='Subscription saved.' />
      </FormGroup>
    );
  }

  handleClose() {
    this.props.clearSubscriptionDetail();
  }
  render() {
    const {
      subscriptionTypes,
      subscription,
      currentSubscriptionTypeId,
      success,
      classes,
    } = this.props;
    let { error } = this.props;

    const { vendorName, vendorNumber } = this.props.vendor;

    const { status, feeExempt } = this.state;

    const { comment } = this.state;

    const subscriptionTypeName = _.find(subscriptionTypes, {
      id: parseInt(currentSubscriptionTypeId),
    }).name;

    let { startDate, endDate } = this.state;

    if (startDate) {
      startDate = moment(startDate).format('YYYY-MM-DD');
    } else {
      startDate = null;
    }
    if (endDate) {
      endDate = moment(endDate).format('YYYY-MM-DD');
    } else {
      endDate = null;
    }
    const startDateError = null;
    const endDateError = null;

    if (!error) {
      error = {};
    }

    return (
      <div>
        <Dialog
          open={true}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
          className={classes.root}
          maxWidth='md'
          fullWidth={false}
        >
          <DialogTitle id='form-dialog-title'>
            Subscription Detail{' '}
            <Typography variant='caption'>{subscriptionTypeName}</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant='subheading'>{`${vendorName} (${vendorNumber})`}</Typography>
            <Divider />
            <FormControl component='fieldset' className={classes.formControl}>
              <FormGroup className={classes.radio}>
                <FormLabel component='label' className={classes.formLabel}>
                  Status
                </FormLabel>
                <RadioGroup
                  aria-label='Status'
                  name='subStatus'
                  className={classes.group}
                  value={status}
                  onChange={(v) => {
                    this.changeSubscriptionStatus(v);
                  }}
                >
                  <FormControlLabel
                    className={classes.radioItem}
                    value='ACTIVE'
                    control={<Radio />}
                    label='Active'
                  />
                  <FormControlLabel
                    className={classes.radioItem}
                    value='INACTIVE'
                    control={<Radio />}
                    label='Inactive'
                  />
                </RadioGroup>
              </FormGroup>

              <FormGroup className={classes.radio}>
                <FormLabel component='label' className={classes.formLabel}>
                  Fee Exempt
                </FormLabel>
                <RadioGroup
                  aria-label='Fee Exempt'
                  name='subStatus'
                  className={classes.group}
                  value={feeExempt.toString()}
                  onChange={(v) => {
                    this.changeFeeExemptStatus(v);
                  }}
                >
                  <FormControlLabel
                    value='true'
                    className={classes.radioItem}
                    control={<Radio />}
                    label='Yes'
                  />
                  <FormControlLabel
                    value='false'
                    className={classes.radioItem}
                    control={<Radio />}
                    label='No'
                  />
                </RadioGroup>
              </FormGroup>

              <FormGroup>
                <TextField
                  id='comment'
                  error={error.comment}
                  helperText={error.comment}
                  label='Comment'
                  style={{ minHeight: '3em' }}
                  multiline
                  rowsMax='6'
                  rows='4'
                  defaultValue={comment}
                  className={classes.textField}
                  onChange={(e) => {
                    this.changeComment(e);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormGroup>

              <FormGroup
                style={{
                  flex: 2,
                  flexDirection: 'row',
                  justifyContent: 'stretch',
                  paddingTop: '1.3em',
                }}
              >
                <TextField
                  id='date'
                  error={error.startDate}
                  helperText={error.startDate}
                  label='Start Date'
                  type='date'
                  defaultValue={startDate}
                  className={classes.textField}
                  onChange={(e) => {
                    this.setState({ startDate: e.target.value });
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  id='date'
                  style={{ paddingLeft: 10 }}
                  label='End Date'
                  error={error.endDate}
                  helperText={error.endDate}
                  type='date'
                  defaultValue={endDate}
                  className={classes.textField}
                  onChange={(e) => {
                    this.setState({ endDate: e.target.value });
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { marginLeft: 10 },
                  }}
                />
              </FormGroup>

              {success ? this.successMessage() : null}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              className={classes.bottomButton}
              variant='raised'
              onClick={this.handleClose}
              color='primary'
            >
              Cancel
            </Button>
            <Button
              className={classes.bottomButton}
              variant='raised'
              onClick={this.saveSubscription}
              color='primary'
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  const { subscriptionDetail, error, success } = state.subscriptions;
  return {
    user,
    subscription: subscriptionDetail,
    error,
    success,
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goHome,
      upsertSubscription,
      clearSubscriptionDetail,
    },
    dispatch
  );

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(SubscriptionsDetail);
