import { hasRole, isColorVPNReportEnabled } from '../featureFlag';

export default {
  regions: [
    {
      id: '10',
      name: 'WA/AK FULL LINE',
    },
    {
      id: '50',
      name: 'OR FULL LINE',
    },
    {
      id: '52',
      name: 'SW FULL LINE',
    },
    {
      id: '90',
      name: 'NCAL FULL LINE',
    },
    {
      id: '130',
      name: 'SCAL SO FULL LINE',
    },
    {
      id: '140',
      name: 'SCAL NO FULL LINE',
    },
    {
      id: '250',
      name: 'MW FULL LINE',
    },
    {
      id: '540',
      name: 'MANHATTAN',
    },
    {
      id: '500',
      name: 'NE FULL LINE',
    },
    {
      id: '600',
      name: 'TX FULL LINE',
    },
    {
      id: '650',
      name: 'SE FULL LINE',
    },
    {
      id: '680',
      name: 'PR FULL LINE',
    },
    {
      id: '40',
      name: 'NW/NCAL/HI RKS',
    },
    {
      id: '110',
      name: 'NW RACKS',
    },
    {
      id: '160',
      name: 'SW RACKS',
    },
    {
      id: '135',
      name: 'SCAL RACKS',
    },
    {
      id: '260',
      name: 'SE RACKS',
    },
    {
      id: '270',
      name: 'MW RACKS',
    },
    {
      id: '998',
      name: 'RACK WHSE',
    },
    {
      id: '274',
      name: 'NE RACKS',
    },
    {
      id: '285',
      name: 'CT RACKS',
    },
    {
      id: '290',
      name: 'SO RACKS',
    },
    {
      id: '6000',
      name: 'DIRECT',
    },
    {
      id: '1550',
      name: 'OP CANADA',
    },
    {
      id: '1350',
      name: 'FL CA',
    },
    {
      id: '6500',
      name: 'N.CA',
    },
    {
      id: '400',
      name: 'OFFPRICE ONLINE',
    },
    {
      id: '3500',
      name: 'TRUNK CLUB TRUNKS',
    },
    {
      id: '3550',
      name: 'TRUNK CLUB CLUBHOUSE',
    },
    {
      id: '8500',
      name: 'OMNI.COM',
    },
  ],
  reports: [
    {
      name: 'Sales and Inventory by Dept-Locations',
      tag: 'sales-and-inventory-by-dept-locations',
      urlFragment: '/reporting/SalesAndInventoryByDeptLocations',
      parameters: ['departmentNumber', 'weeks'],
    },
    {
      name: 'Sales and Inventory by Dept-VPN',
      tag: 'sales-and-inventory-by-dept-vpn',
      urlFragment: '/reporting/SalesAndInventoryByDeptVpnAll',
      parameters: ['departmentNumber', 'weeks'],
    },
    {
      name: 'Sales and Inventory by Dept-VPN (By Bus Unit-Group-Region)',
      tag: 'sales-and-inventory-by-dept-vpn-by-bus-unit-group-region',
      urlFragment:
        '/reporting/SalesAndInventoryByDeptVpnBusinessUnitGroupRegion',
      parameters: ['departmentNumber', 'weeks'],
    },
    {
      name: 'Sales and Inventory by Dept-VPN (By Region-Location)',
      tag: 'sales-and-inventory-by-dept-vpn-by-region-location',
      urlFragment: '/reporting/SalesAndInventoryByDeptVpnRegionLocation',
      parameters: ['departmentNumber', 'weeks', 'regions'],
    },
    {
      name: 'Sales and Inventory by Dept-VPN/Color',
      tag: 'vpn-color',
      urlFragment: '/reporting/SalesAndInventoryByDeptVpnColor',
      parameters: ['departmentNumber', 'weeks'],
      fileType: 'pdf',
    },
    {
      name: 'Supplier Color Size Detail',
      tag: 'supplier-color-size-detail',
      urlFragment: '/reporting/SalesAndInventoryByDeptVpnColorSize',
      parameters: ['departmentNumber', 'weeks', 'vpn'],
    },
    {
      name: 'TY-LY by Dept-Locations',
      tag: 'tyly-by-dept-locations',
      urlFragment: '/reporting/SalesAndInventoryTyLyByDeptLocations',
      parameters: ['departmentNumber', 'weeks'],
    },
    /*
    ,
    {
      "name": "NQC Supplier Results - Top Defective VPN's",
      "urlFragment": "/reporting/NQCSalesAndInventoryByDeptRegions",
      "parameters": [
        "departmentNumber",
        "weeks",
        "regions"
      ]
    }
    */
  ],
};
