import React from 'react';
import withStyles from 'material-ui/styles/withStyles';
/*
  Contact Us Page
*/
const styles = (theme) => ({});

const CONTACT = ({}) => {
  return (
    <div>
      <h2>Nordstrom Supplier Reports - Contact Us</h2>
      <p>
        For questions concerning information on Supplier Reports, or to gain
        access to Supplier Reports, please contact your Nordstrom Buyer. If you
        have an existing account and need help accessing or navigating our
        Supplier Reports, please email{' '}
        <a href='mailto:supplier.reports@nordstrom.com'>
          supplier.reports@nordstrom.com
        </a>
        . Please include Supplier Number, Supplier Name, Contact Name in your
        email.
      </p>
    </div>
  );
};

export default withStyles(styles)(CONTACT);
