import React, { Component } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { searchVendors, clearSearch } from '../../actions/search';
import {
  getSubscriptionTypes,
  clearSubscriptionDetail,
} from '../../actions/subscriptions';
import { checkCurrentUserToken } from '../../utils/apiUtils';

import SearchBox from '../../components/generic/SearchBox';
import './subscriptions.css';

import SubscriptionsSearchResults from './SubscriptionsSearchResults';
import * as _ from 'lodash';
import Button from 'material-ui/Button';
import Typography from 'material-ui/Typography/Typography';
import Alert from '../../components/generic/Alert';
import { withStyles } from 'material-ui/styles';
import LinearProgress from 'material-ui/Progress/LinearProgress';

const styles = (theme) => ({
  heading: {
    fontSize: '1.3em',
  },
  cmdButton: {
    'display': 'flex',
    'margin': 10,
    '&:hover': {
      color: theme.palette.secondary,
    },
    '&:active': {
      color: theme.palette.secondary,
    },
  },
  error: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 18,
  },
  searchBoxes: {
    flexDirection: 'row',
    display: 'flex',
    marginTop: '1rem',
  },
  searchBox: {},
  middleOr: {
    paddingLeft: 20,
    paddingRight: 20,
    alignSelf: 'center',
  },
  searching: {
    display: 'flex',
    flex: '0 1 33%',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  progressText: {
    marginBottom: '0.5rem',
  },
});

class SubscriptionLookup extends Component {
  state = {
    supplierId: '',
    valid: false,
  };
  constructor(props) {
    super(props);

    this.updateParams = this.updateParams.bind(this);
    this.submit = this.submit.bind(this);

    this.state = {
      supplierId: '',
      valid: false,
    };
    this.props.getSubscriptionTypes();
  }

  componentWillReceiveProps(nextProps) {
    this.props.checkCurrentUserToken();
  }
  submit(e) {
    if (!this.props.valid) {
      e.preventDefault();
      return 0;
    }
    this.props.clearSearch();
    this.props.clearSubscriptionDetail();

    const { supplierId } = this.state;
    const { user } = this.props;
    e.preventDefault();
    const { searchType } = this.props;
    const parm = {};
    parm[searchType] = this.props.keepState[searchType];

    this.props.searchVendors(parm);
  }

  updateParams(e) {
    const { value } = e.target;

    this.setState({ supplierId: value });
    this.props.setPartnerNumber(value);
  }

  getError() {
    if (this.props.error) {
      const { error } = this.props;
      if (_.isString(error)) {
        return <Alert variant='error' message={error} />;
      } else {
        let { message } = error;
        if (!message) {
          message = error.error;
        }

        return <Alert variant='error' message={message} />;
      }
    }
    return null;
  }

  getProgress() {
    const {
      searchType,
      keepState,
      loading,
      loadingSubscriptionDetail,
      vendorNumber,
      classes,
    } = this.props;

    let txt = 'Searching...';
    let searchValue = '';

    if (loading) {
      const searchValue = keepState[this.props.searchType];

      switch (searchType) {
        case 'vendorName':
          txt = 'Searching suppliers with names like';
          break;
        case 'vendorNumber':
          txt = 'Searching supplier number';
          break;
      }

      return (
        <div>
          <Typography variant='subtitle' className={classes.progressText}>
            {txt}: <b>{searchValue}</b>
          </Typography>
          <div>
            <LinearProgress data-test-id='progress-indicator' />{' '}
          </div>
        </div>
      );
    }

    if (loadingSubscriptionDetail) {
      txt = 'Loading subscription details for';
      searchValue = vendorNumber;
      return (
        <div>
          <Typography variant='subtitle' className={classes.progressText}>
            {txt}: <b>{searchValue}</b>
          </Typography>
          <div>
            <LinearProgress data-test-id='progress-indicator' />{' '}
          </div>
        </div>
      );
    }
    return null;
  }

  renderTitle() {
    const { subscriptionTypes, currentSubscriptionTypeId } = this.props;

    const st = _.find(subscriptionTypes, {
      id: parseInt(currentSubscriptionTypeId),
    });
    if (!st) {
      return null;
    }
    return <span>Manage {st.description}</span>;
  }
  render() {
    const { classes } = this.props;

    return (
      <div>
        <form onSubmit={this.submit}>
          <Typography variant='title' className={classes.heading}>
            Supplier Subscription Mangement
          </Typography>
          <div className={classes.searchBoxes}>
            <div
              className={classes.searchBox}
              style={{ alignItems: 'flex-end' }}
            >
              <SearchBox
                history={this.props.history}
                searchType='vendorName'
                label='Supplier Name'
                activeSearchType={this.props.searchType}
              />
            </div>
            <div className={classes.middleOr}>
              <span>- OR -</span>
            </div>
            <div
              className={classes.searchBox}
              style={{ alignItems: 'flex-end' }}
            >
              <SearchBox
                className={classes.searchBox}
                history={this.props.history}
                searchType='vendorNumber'
                label='Supplier Number'
                activeSearchType={this.props.searchType}
              />
            </div>
            <div
              className={classes.searching}
              style={{ alignItems: 'flex-end', paddingTop: '0.75em' }}
            >
              <Button
                variant='raised'
                type='submit'
                className={classes.cmdButton}
                color='primary'
                disabled={!this.props.valid}
                onClick={this.submit}
              >
                <i className='fa fa-search' aria-hidden='true'></i>Search
              </Button>

              <Button
                variant='raised'
                className={classes.cmdButton}
                color='secondary'
                onClick={() => {
                  this.props.clearSearch();
                }}
              >
                <i
                  className='fa fa-refresh'
                  aria-hidden='true'
                  title='Reset'
                  alt='Clear Form'
                ></i>
              </Button>
            </div>
          </div>

          {this.getProgress()}
          <div className={classes.error}>{this.getError()}</div>
          {this.props.results ? (
            <SubscriptionsSearchResults
              currentSubscriptionTypeId={this.props.currentSubscriptionTypeId}
            />
          ) : null}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { error, loading, searchType, keepState, valid, results, complete } =
    state.search;
  const { subscriptionTypes, loadingSubscriptionDetail, vendorNumber } =
    state.subscriptions;

  return {
    user: state.auth.user,
    error,
    loading,
    searchType,
    keepState,
    valid,
    results,
    complete,
    subscriptionTypes,
    loadingSubscriptionDetail,
    vendorNumber,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    searchVendors,
    clearSearch,
    checkCurrentUserToken,
    getSubscriptionTypes,
    clearSubscriptionDetail,
  })
)(SubscriptionLookup);
