import React from 'react';
import withStyles from 'material-ui/styles/withStyles';
import departmentMappingGuide from './img/guideToDeptChanges_v2.png';
import nordstromDeptMapping from './img/Nordstrom Dept Conversion v4.xlsx';
import Button from 'material-ui/Button/Button';
/*
  Contact Us Page
*/
const styles = (theme) => ({});

const CONTACT = ({}) => {
  return (
    <div>
      <div>
        <a href={nordstromDeptMapping} download>
          <Button
            variant='raised'
            color='default'
            title='Click here to download new department mapping excel document'
          >
            Download Dept Mapping
          </Button>
        </a>
      </div>
      <br />
      <img src={departmentMappingGuide} />
    </div>
  );
};
export default withStyles(styles)(CONTACT);
