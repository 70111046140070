/* eslint-disable no-unused-vars  */
/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from 'react';

import * as _ from 'lodash';
import { connect } from 'react-redux';
import {
  changeSearchFocus,
  changeSearchValidity,
  clearSearch,
} from '../../actions/search';

import './search.css';
import { compose } from 'redux';
import withStyles from 'material-ui/styles/withStyles';

const ERROR_VENDOR_NAME_TOO_SHORT = '3 characters or more';
const ERROR_BRANDLABEL_TOO_SHORT = ERROR_VENDOR_NAME_TOO_SHORT;
const ERROR_INVALID_VENDOR_NUMBER = 'Number not valid';
const ERROR_VENDOR_NUMBER_TOO_SHORT = '2 or more numbers';

const styles = (theme) => ({
  input: {
    padding: '0.43em',
    fontSize: '1rem',
    lineHeight: 1.25,
  },
});

class SearchBox extends Component {
  constructor(props) {
    super(props);

    this.changeText = this.changeText.bind(this);
    this.validateName = this.validateName.bind(this);
    this.validateNumber = this.validateNumber.bind(this);
    this.changeFocus = this.changeFocus.bind(this);
    this.lostFocus = this.lostFocus.bind(this);

    this.state = {
      criteria: {
        name: '',
        status: { active: true, inactive: false, potential: false },
        markets: ['usa', 'can'],
      },
      valid: false,
      validationMessage: null,
      changed: false,
      inputText: this.props.keepState[this.props.searchType],
      hasFocus: false,
    };
  }
  componentWillMount() {
    this.state.inputText = this.props.keepState[this.props.searchType];
  }

  changeFocus(e) {
    const target = e.target;

    this.state.inputText = this.props.keepState[this.props.searchType];
    if (this.state.inputText) {
      setTimeout(() => {
        target.setSelectionRange(0, this.state.inputText.length);
      }, 5);
    }

    switch (this.props.searchType) {
      case 'vendorName':
        this.validateName();
        break;
      case 'vendorNumber':
        this.validateNumber();
        break;
    }
    this.state.hasFocus = true;
    this.props.changeSearchFocus(this.props.searchType, this.state.inputText);
  }
  lostFocus(e) {
    this.state.hasFocus = false;
  }
  changeText(e) {
    this.props.clearSearch();
    this.state.inputText = e.target.value;

    this.props.changeSearchFocus(this.props.searchType, e.target.value);

    switch (this.props.searchType) {
      case 'vendorNumber':
        return this.validateNumber();
      case 'vendorName':
        return this.validateName();
      default:
        throw new Error(
          'invalid searchType, only vendorNumber, vendorName, and brandLabel are supported'
        );
    }
  }
  validateName() {
    const vendorName = this.state.inputText;

    if (!vendorName) {
      this.setState({ validationMessage: null, valid: false });
      this.props.changeSearchValidity({ valid: false });
      return;
    }
    if (vendorName.length > 0 && vendorName.length < 3) {
      this.setState({
        validationMessage: ERROR_VENDOR_NAME_TOO_SHORT,
        valid: false,
      });
      this.props.changeSearchValidity({ valid: false });
      return;
    }

    this.setState({ validationMessage: null, valid: true });
    this.props.changeSearchValidity({ valid: true });
  }

  validateNumber() {
    const vendorNumber = this.state.inputText;

    if (!vendorNumber) {
      this.setState({ validationMessage: null, valid: false });
      this.props.changeSearchValidity({ valid: false });
      return;
    }
    if (vendorNumber.length > 0 && vendorNumber.length < 2) {
      this.setState({
        validationMessage: ERROR_VENDOR_NUMBER_TOO_SHORT,
        valid: false,
      });
      this.props.changeSearchValidity({ valid: false });
      return;
    }
    const reg = /^\d+$/;

    if (!reg.test(vendorNumber)) {
      this.setState({
        validationMessage: ERROR_INVALID_VENDOR_NUMBER,
        valid: false,
      });
      this.props.changeSearchValidity({ valid: false });
      return;
    }

    const fvm = parseFloat(vendorNumber);

    if (!_.isInteger(fvm)) {
      this.setState({
        validationMessage: ERROR_INVALID_VENDOR_NUMBER,
        valid: false,
      });
      this.props.changeSearchValidity({ valid: false });
      return;
    }

    this.setState({ validationMessage: null, valid: true });

    this.props.changeSearchValidity({ valid: true });
  }

  render() {
    const labelClass =
      this.props.searchType === this.props.activeSearchType ||
      this.state.hasFocus
        ? 'ifocus'
        : 'ofocus';
    const { classes } = this.props;
    const inputName = 'txt' + this.props.searchType;
    const buttonColor = '#048dcc';
    return (
      <div className={labelClass}>
        <div className='form-group'>
          <label htmlFor={inputName}>{this.props.label}</label>
          <br />
          <input
            name='inputBox'
            className={'form-control ' + classes.input}
            onChange={this.changeText}
            onFocus={this.changeFocus}
            onBlur={this.lostFocus}
            value={this.props.keepState[this.props.searchType]}
            type='text'
            id={inputName}
          />
          {this.state.validationMessage &&
          this.state.hasFocus &&
          this.state.inputText ? (
            <div>
              <label className='invalidInput'>
                {this.state.validationMessage}
              </label>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    vendorNumber: state.search.vendorNumber,
    vendorName: state.search.vendorName,
    complete: state.search.complete,
    lastType: state.search.searchType,
    loading: state.search.isSearching,
    keepState: state.search.keepState,
    currentSearchType: state.search.searchType,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    changeSearchFocus,
    changeSearchValidity,
    clearSearch,
  })
)(SearchBox);
