import { ConfigObject } from './types';

// Deployment URL: https://vendor-portal-ci.nordstromaws.app
const dev: ConfigObject = {
  accountUrl: 'https://partneraccess-nonprod.nordstrom.com',
  appUrl: 'https://vendor-portal-ci.nordstromaws.app',
  authRedirectUrl: 'https://vendor-portal-ci.nordstromaws.app',
  authServiceUrl:
    'https://api-sandbox.nordstromaws.app/app01396-sandbox/vendorauthentication',
  reportApiUrl:
    'https://api-sandbox.nordstromaws.app/app01396-sandbox/vendorreporting',
  subscriptionsUrl:
    'https://api-sandbox.nordstromaws.app/app01396-sandbox/vendorsubscriptions',
};

export default dev;
