import * as types from './types';
import currentConfig from '../configuration';
import {
  callApi,
  setIdToken,
  removeIdToken,
  getProfile,
} from '../utils/apiUtils';

const okta_error_descriptions = {
  'unauthorized_client':
    'The client is not authorized to request an authorization code using this method.',
  'access_denied':
    'You do not have permission to access the Supplier Reports. Please contact SupplierReports@nordstrom.com to request access to the Sales and Inventory Report on the Supplier Reports.',
  'unsupported_response_type':
    'The authorization server does not support obtaining an authorization code using this method.',
  'unsupported_response_mode':
    'The authorization server does not support the requested response mode.',
  'invalid_scope': 'The requested scope is invalid, unknown, or malformed.',
  'server_error':
    'The authorization server encountered an unexpected condition that prevented it from fulfilling the request.',
  'temporarily_unavailable':
    'The authorization server is currently unable to handle the request due to a temporary overloading or maintenance of the server.',
  'invalid_client': 'The specified client is not valid.',
  'login_required	':
    'The client specified not to prompt, but the user is not logged in.',
  'invalid_request': 'The request parameters are not valid.',
  'user_canceled_request': 'User canceled the social login request.',
};

export const REDIRECT_TO_AUTHENTICATION = 'REDIRECT_TO_AUTHENTICATION';

function getLoginUrlRequest() {
  return {
    type: types.GET_LOGIN_URL_REQUEST,
    gettingLoginUrl: true,
  };
}

export function getLoginUrl() {
  const { authRedirectUrl, authServiceUrl } = currentConfig;
  const config = {};
  config.method = 'GET';

  function success(urlData) {
    return {
      type: types.GET_LOGIN_URL_SUCCESS,
      loginUrl: urlData,
      gettingLoginUrl: false,
    };
  }
  function err(e) {
    return {
      type: types.GET_LOGIN_URL_FAILURE,
      error: e,
      gettingLoginUrl: false,
    };
  }
  const checkLoginUrl = `${authServiceUrl}/vendor/auth/loginurl?redirect_url=${authRedirectUrl}`;

  return callApi(
    checkLoginUrl,
    config,
    getLoginUrlRequest(),
    success,
    err,
    true
  );
}

export function goHome(history) {
  return (dispatch, state) => {
    window.location.replace(process.env.PUBLIC_URL + '/#/');

    return dispatch({
      type: types.GO_HOME,
    });
  };
}

function exchangeSuccess(payload) {
  return (dispatch) => {
    try {
      setIdToken(payload);
    } catch (e) {
      return loginFailure(e);
    }

    const profile = getProfile();

    if (profile && profile.sub) {
      dispatch({
        type: types.KEY_EXCHANGE_SUCCESS,
        user: profile,
      });

      window.location.replace(process.env.PUBLIC_URL + '/#/');
    } else {
      return loginFailure(
        'Access to this application cannot be granted at this time. Please contact support or try logging in at a later time.'
      );
    }
  };
}

function loginFailure(error) {
  removeIdToken();
}

function exchangeRequest() {
  return (dispatch) => {
    return dispatch({
      type: types.KEY_EXCHANGE_REQUEST,
    });
  };
}
function exchangeError(err) {
  const error = okta_error_descriptions[err] || 'Unable to authorize user';

  return {
    type: types.KEY_EXCHANGE_FAILURE,
    user: null,
    error,
    roles: [],
  };
}

export function exchangeCodeAndState(code, authstate, history, err = null) {
  const { authRedirectUrl, authServiceUrl } = currentConfig;
  const config = { 'content-type': 'text/plain' };

  config.method = 'POST';
  if (!err) {
    return (dispatch) => {
      dispatch(
        callApi(
          `${authServiceUrl}/vendor/auth/tokens?code=${code}&state=${authstate}&redirectUri=${authRedirectUrl}`,
          config,
          exchangeRequest(),
          exchangeSuccess,
          exchangeError,
          true
        )
      );
    };
  } else {
    return (dispatch) => {
      dispatch(exchangeError(err));
    };
  }
}

export function resolvePendingAccountActions() {
  const { accountUrl } = currentConfig;
  window.open(`${accountUrl}/profile`, '_new');

  return (dispatch) =>
    dispatch({
      type: types.RESOLVE_PENDING_ACCOUNT_ACTIONS,
    });
}

export function logout(accessToken) {
  return (dispatch) => {
    const logoutUrl = `${currentConfig.authServiceUrl}/vendor/auth/logout`;
    const config = {
      headers: {
        accessToken: accessToken,
      },
    };
    config.method = 'GET';
    const req = function () {
      return {
        type: types.LOGOUT_REQUEST,
        loggingOut: true,
        error: null,
      };
    };
    const success = function () {
      removeIdToken();

      return {
        type: types.LOGOUT_SUCCESS,
        user: null,
        loggingOut: false,
        error: null,
      };
    };
    const failure = function (err) {
      return {
        type: types.LOGOUT_FAILURE,
        loggingOut: false,
        error: err,
      };
    };
    dispatch(req());
    return dispatch(
      callApi(logoutUrl, config, function () {}, success, failure)
    );
  };
}
