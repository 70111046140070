import { Component } from 'react';
import MotdEditor from '../home/MotdEditor';
import cover1a from '../home/cover1a.jpg';
import cover2a from '../home/cover2a.jpg';
import cover3a from '../home/cover3a.jpg';
import cover3b from '../home/cover3b.jpg';
import Button from 'material-ui/Button/Button';
import React from 'react';
import { compose } from 'redux';
import withStyles from 'material-ui/styles/withStyles';
import { connect } from 'react-redux';
import {
  cancelEdit,
  getContent,
  saveContent,
  startEdit,
} from '../../actions/content';
import { checkCurrentUserToken } from '../../utils/apiUtils';
import Alert from '../../components/generic/Alert';

const styles = (theme) => ({
  button: {
    '& .fa': {
      marginRight: '1em',
    },
  },
});

class EditContentPage extends Component {
  constructor(props) {
    super(props);
    this.changeContentText = this.changeContentText.bind(this);

    this.showErrors = this.showErrors.bind(this);
    this.state = {
      newContent: props.content,
    };
  }
  componentWillMount(nextProps) {
    this.props.checkCurrentUserToken();
    this.props.getContent();

    if (!this.props.user.isContentEditor()) {
      this.props.history.push('/');
    }
  }

  changeContentText(event) {
    this.setState({ newContent: event.target.value });
  }

  showErrors() {
    if (this.props.saveError) {
      return <Alert variant='error' message={this.props.saveError} />;
    }

    return null;
  }

  render() {
    return (
      <div className={this.props.saving ? 'motd_edit saving' : 'motd_edit'}>
        <div className='landing motd'>
          <h1>Welcome to Supplier Reports</h1>
          {this.showErrors()}
          <MotdEditor />
          <hr />
          <div className='images'>
            <img src={cover1a} />
            <img src={cover2a} />
            <img src={cover3a} />
            <img src={cover3b} />
          </div>
        </div>
        <div style={{ width: '100%', textAlign: 'right', paddingTop: '15px' }}>
          <Button
            color='primary'
            disabled={this.props.saving}
            onClick={(e) => {
              debugger;
              this.props.saveContent(this.props.user, this.props.draft);
            }}
          >
            {this.props.saving ? (
              <i className='fa fa-clock-o' />
            ) : (
              <i className='fa fa-check-square-o' aria-hidden='true'></i>
            )}
            &nbsp;{this.props.saving ? 'Working...' : 'Save'}
          </Button>
          &nbsp;
          <Button
            color='secondary'
            disabled={this.props.saving}
            onClick={(e) => this.props.cancelEdit(this.props.history)}
          >
            <i
              onClick={this.props.cancelEdit}
              className='fa fa-window-close'
              aria-hidden='true'
            ></i>{' '}
            Cancel
          </Button>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, error } = state.auth;
  const { home, edit, saveError, saving, draft } = state.content;
  return {
    loading: loggingIn,
    content: home,
    user,
    edit,
    saving,
    saveError,
    draft,
    error,
  };
}
export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    startEdit,
    getContent,
    cancelEdit,
    saveContent,
    checkCurrentUserToken,
  })
)(EditContentPage);
