import _ from 'lodash';
import moment from 'moment';
import * as types from './types';
import currentConfig from '../configuration';
import {
  callApi,
  headers,
  downloadFromApi,
  REPORTING_UNAVAILABLE,
} from '../utils/apiUtils';

function suppliersRequest() {
  return {
    type: types.SUPPLIERS_REQUEST,
  };
}

function suppliersSuccess(payload) {
  return (dispatch) =>
    dispatch({
      type: types.SUPPLIERS_SUCCESS,
      suppliers: payload.suppliers,
    });
}

function suppliersFailure(error) {
  return {
    type: types.SUPPLIERS_FAILURE,
    suppliers: [],
    error,
    loading: false,
  };
}

function getReportRequest(report) {
  report.loading = true;
  return {
    type: types.GET_REPORT_REQUEST,
    query: null,
    success: false,
    report,
  };
}

function getReportSuccess(data, report) {
  return {
    type: types.GET_REPORT_SUCCESS,
    success: true,
    report: report,
  };
}

function getReportError(e, report) {
  report.loading = false;

  return {
    type: types.GET_REPORT_ERROR,
    success: false,
    error: e.message,
    report,
  };
}

export function getReport(query, supplierId, departmentId, report) {
  return (dispatch) => {
    const frag = report.urlFragment + '.' + query.fileType;

    let url = `${currentConfig.reportApiUrl}${frag}?supplierId=${supplierId}&departmentId=${departmentId}&numberOfWeeks=${query.weeks}`;
    if (query.regions) {
      var qry = '&regions=';
      _.each(query.regions, function (r, i) {
        qry += r;
        if (i !== query.regions.length - 1) {
          qry += ',';
        }
      });
      url += qry;
    }

    if (query.vpns) {
      var qry = '&vpns=';
      _.each(query.vpns, function (r, i) {
        qry += r;
        if (i !== query.vpns.length - 1) {
          qry += ',';
        }
      });
      url += qry;
    }
    let baseName = frag.split('/')[2].split('?')[0];
    const fileExt = baseName.split('.')[1];
    baseName = baseName.split('.')[0];

    // Example: SalesAndInventoryByDeptLocations_103_2017-10-17_19.56.51.pdf
    const dateName = moment().format('YYYY-MM-DD_HH.mm.ss');
    const fileName = `${baseName}_${departmentId}_${dateName}.${fileExt}`;

    const h = headers();
    h['Content-Type'] = 'application/x-www-form-urlencoded';
    delete h.Accept;

    const config = {
      method: 'GET',
      headers: h,
    };

    const success = function (data) {
      return getReportSuccess(data, report);
    };
    const err = function (e) {
      return getReportError(e, report);
    };
    return dispatch(
      downloadFromApi(
        fileName,
        url,
        config,
        () => {
          return dispatch(getReportRequest(report));
        },
        success,
        err
      )
    );
  };
}

export function postReport(requestBody, fileType, departmentId, report) {
  return (dispatch) => {
    const frag = report.urlFragment + '.' + fileType;

    const url = `${currentConfig.reportApiUrl}${frag}`;

    let baseName = frag.split('/')[2].split('?')[0];
    const fileExt = baseName.split('.')[1];
    baseName = baseName.split('.')[0];

    // Example: SalesAndInventoryByDeptLocations_103_2017-10-17_19.56.51.pdf
    const dateName = moment().format('YYYY-MM-DD_HH.mm.ss');
    const fileName = `${baseName}_${departmentId}_${dateName}.${fileExt}`;

    const h = headers();

    const config = {
      method: 'POST',
      headers: h,
      body: JSON.stringify(requestBody),
    };

    const success = function (data) {
      return getReportSuccess(data, report);
    };
    const err = function (e) {
      return getReportError(e, report);
    };
    return dispatch(
      downloadFromApi(
        fileName,
        url,
        config,
        () => {
          return dispatch(getReportRequest(report));
        },
        success,
        err
      )
    );
  };
}

export function setSupplier(supplier, user) {
  return (dispatch, state) => {
    if (user.canAccessSupplier(supplier.id)) {
      const current = { id: supplier.id, name: supplier.name };

      return dispatch({
        type: types.SET_SUPPLIER,
        current,
      });
    }
  };
}

export function setReportParameters(parm, value) {
  return (dispatch, state) => {
    const s = state();
    const q = s.suppliers.query || {};
    q[parm] = value;
    dispatch({
      type: types.SET_REPORTS_PARAMS,
      query: q,
    });
  };
}

export function setPartnerNumber(supplierId) {
  return {
    type: types.SET_SUPPLIER_ID,
    viewSupplierId: supplierId,
  };
}

// Retrieves the list of suppliers for the currently logged-in user.
export function getSuppliers(supplierIds) {
  return (dispatch) => {
    if (supplierIds.length === 0) {
      return;
      dispatch({
        type: types.SUPPLIERS_SUCCESS,
        suppliers: [],
      });
    }
    const supplierLookups = {
      supplierIds: supplierIds,
    };

    const config = {
      method: 'post',
      headers: headers(),
      body: JSON.stringify(supplierLookups),
    };

    const url = `${currentConfig.appUrl}/api/supplierInfo`;
    dispatch(suppliersRequest());

    return dispatch(
      callApi(url, config, suppliersRequest, suppliersSuccess, suppliersFailure)
    );
  };
}

const singleSupplierRequest = () => {
  return {
    type: types.SINGLE_SUPPLIER_REQUEST,
  };
};

const singleSupplierSuccess = (payload) => {
  const { departments } = payload;
  payload.departments = departments.map((d) => {
    d.displayText = `${d.departmentCode} - ${d.departmentName}`;
    return d;
  });

  return {
    type: types.SINGLE_SUPPLIER_SUCCESS,
    payload,
  };
};

const singleSupplierFailure = (error) => {
  return {
    type: types.SINGLE_SUPPLIER_FAILURE,
    payload: error,
  };
};

export const clearSingleSupplier = () => {
  return {
    type: types.SINGLE_SUPPLIER_CLEAR,
  };
};

export const getSingleSupplier = ({ supplierId, user }) => {
  return (dispatch) => {
    if (!user.canAccessSupplier(supplierId)) {
      return dispatch(
        singleSupplierFailure(
          new Error(
            'user attempted to access a supplier to which they are not associated'
          )
        )
      );
    }

    const config = {
      method: 'get',
      headers: headers(),
    };

    const url = `${currentConfig.appUrl}/api/supplier/${supplierId}`;

    dispatch(singleSupplierRequest());

    return dispatch(
      callApi(
        url,
        config,
        singleSupplierRequest,
        singleSupplierSuccess,
        singleSupplierFailure
      )
    );
  };
};

function checkReportingRequest() {
  return {
    type: types.REPORT_STATUS_REQUEST,
  };
}
function checkReportingSuccess() {
  return {
    type: types.REPORT_STATUS_SUCCESS,
  };
}
function checkReportingError(e) {
  return {
    type: types.REPORT_STATUS_ERROR,
    available: e && e.message === REPORTING_UNAVAILABLE ? false : true,
  };
}
export const checkReporting = () => {
  return (dispatch) => {
    const config = {
      method: 'get',
      headers: headers(),
    };

    const url = `${
      currentConfig.reportApiUrl
    }/reporting/status?_c=${new Date().valueOf()}`;
    dispatch(checkReportingRequest());
    return dispatch(
      callApi(url, config, () => {}, checkReportingSuccess, checkReportingError)
    );
  };
};
