import React, { Component } from 'react';

export default class NotFound extends Component {
  render() {
    return (
      <div style={{ margin: '0 auto', width: '400px' }}>
        <h1>404!</h1>
        <p>Oops, no such page exists.</p>
      </div>
    );
  }
}
