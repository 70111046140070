// @flow weak
/* eslint-disable no-underscore-dangle */

import { create } from 'jss';
import preset from 'jss-preset-default';
import { SheetsRegistry } from 'react-jss/lib/jss';
import { createMuiTheme } from 'material-ui/styles';
import {
  blueGrey,
  grey,
  red,
  lightGreen,
  yellow,
  lightBlue,
} from 'material-ui/colors';
import createGenerateClassName from 'material-ui/styles/createGenerateClassName';

const theme = createMuiTheme({
  pageMessage: {
    fontSize: 14,
    color: 'grey',
  },
  palette: {
    primary: blueGrey,
    // TODO: need to customize this further to match Nordstrom
    // https://material-ui-1dab0.firebaseapp.com/customization/themes/
    secondary: grey,
    error: red,
    success: lightGreen,
    warning: yellow,
    info: lightBlue,
    highlight: blueGrey[50],
    offWhite: '#fafafa',
    white: 'white',
  },
  title: {
    color: grey,
    display: 'inline-block',
  },
  svgIcon: {
    color: {
      error: red,
      success: lightGreen,
      warning: 'yellow',
    },
  },
  typography: {
    fontFamily: 'BrandonText',
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.87)',
    title: {
      color: blueGrey[700],
      fontSize: '1.15rem',
    },
    headline: {
      color: blueGrey[500],
    },
    subheading: {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  contentMain: {
    marginLeft: 75,
    marginTop: 75,
    marginBottom: 95,
    marginRight: 75,
    minHeight: '100vh',
  },
  overrides: {
    MuiButton: {
      root: {
        '& .fa': {
          marginRight: '0.5em',
        },
      },
    },
    MuiTableCell: {
      head: {
        fontSize: '1.0em',
        fontWeight: '400',
      },
    },
    MuiInput: {
      root: {
        display: 'inline-block',
      },
    },
    MuiExpansionPanelSummary: {
      minHeight: '1em',
      padding: '0 12px 0 12px',
    },
    MuiTypography: {
      subheading: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  },
});

// Configure JSS
const jss = create(preset());
jss.options.createGenerateClassName = createGenerateClassName;

function createContext() {
  return {
    jss,
    theme,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new SheetsRegistry(),
  };
}

export default function getContext() {
  // Make sure to create a new store for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!process.browser) {
    return createContext();
  }

  // Reuse context on the client-side
  if (!global.__INIT_MATERIAL_UI__) {
    global.__INIT_MATERIAL_UI__ = createContext();
  }

  return global.__INIT_MATERIAL_UI__;
}
