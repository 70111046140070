import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSingleSupplier } from '../../actions/suppliers';
import Reports from '../../components/suppliers/Reports';

import { compose } from 'redux';
import withStyles from 'material-ui/styles/withStyles';

const styles = (theme) => ({});

export class ReportsPage extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    const supplierId = this.props.match.params.supplierId;
    const { user } = this.props;

    if (supplierId) {
      this.props.getSingleSupplier({ supplierId, user });
    }
  }
  render() {
    const { classes } = this.props;

    return (
      <div>
        <div>
          {this.props.reports && this.props.supplier ? (
            <Reports
              reports={this.props.reports.reports}
              supplierId={this.props.supplier.id}
              regions={this.props.reports.regions}
              user={this.props.user}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    reports: state.suppliers.reports,
    user: state.auth.user,
    suppliers: state.suppliers.suppliers,
    dispatch: PropTypes.func.isRequired,
    supplier: state.suppliers.singleSupplier,
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getSingleSupplier })
)(ReportsPage);
