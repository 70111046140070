import local from './local';
import dev from './dev';
import * as nonprod from './nonprod';
import * as prod from './prod';
import { ConfigObject } from './types';

const { hostname } = window.location;

// get the current configuration based on the hostname
const config: Record<string, ConfigObject> = {
  // local
  'localhost': local,

  // dev
  'vendor-portal-ci.nordstromaws.app': dev,

  // nonprod
  'vendor-portal-si.nordstromaws.app': nonprod.internal,
  'partnerconnect-staging.nordstrom.com': nonprod.external,

  // prod
  'vendor-portal.nordstromaws.app': prod.internal,
  'partner.nordstrom.com': prod.external,
};

const currentConfig: ConfigObject = config[hostname];

export default currentConfig;
