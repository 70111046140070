import React from 'react';

import './home.css';

import PartnerLookup from '../../components/suppliers/PartnerLookup';
import SearchResults from '../../components/suppliers/SearchResults';

const EmployeeLanding = ({ history, user, changeEditMode }) => {
  return (
    <div className='landing'>
      <div>
        <PartnerLookup history={history} resultComponent={SearchResults} />
      </div>
    </div>
  );
};

export default EmployeeLanding;
