export const SET_MENU_ANCHOR_ELEMENT = 'setMenuAnchorElement';
export const SHOW_PROFILE_MENU = 'showProfileMenu';

const initialState = {
  menuAnchorElement: { getBoundingClientRect: () => {} },
  showProfileMenu: false,
};

export const setMenuAnchorElement = (element) => ({
  type: SET_MENU_ANCHOR_ELEMENT,
  element,
});
export const setShowProfileMenu = (showProfileMenu) => ({
  type: SHOW_PROFILE_MENU,
  showProfileMenu,
});

export default function ui(state = initialState, action) {
  switch (action.type) {
    case SET_MENU_ANCHOR_ELEMENT:
      return {
        ...state,
        menuAnchorElement: action.element,
      };
    case SHOW_PROFILE_MENU:
      return {
        ...state,
        showProfileMenu: action.showProfileMenu,
      };

    default:
      return state;
  }
}
