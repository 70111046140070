import { callApi, defaultHeaders, ID_TOKEN } from '../utils/apiUtils';
import currentConfig from '../configuration';
import * as types from './types';

export const startEdit = () => {
  return {
    type: types.START_EDIT,
  };
};

export const cancelEdit = (history) => {
  history.go(-1);
  return {
    type: types.CANCEL_EDIT,
  };
};

export const getContent = () => {
  return (dispatch) => {
    const { reportApiUrl } = currentConfig;

    const config = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    };
    config.method = 'GET';

    function req() {
      return {
        type: types.GET_CONTENT_REQUEST,
      };
    }

    function success(contentData) {
      return {
        type: types.GET_CONTENT_SUCCESS,
        ...contentData,
      };
    }

    function err(e) {
      return {
        type: types.GET_CONTENT_ERROR,
        error: e,
      };
    }

    const x = new Date().valueOf();

    return dispatch(
      callApi(
        `${reportApiUrl}/reporting/message/content`,
        config,
        req,
        success,
        err,
        true
      )
    );
  };
};

export const saveContent = (user, content) => {
  return (dispatch) => {
    const { reportApiUrl } = currentConfig;
    const headers = defaultHeaders();
    headers.Authorization = `Bearer ${global.localStorage.getItem(ID_TOKEN)}`;

    const config = {
      method: 'POST',
      headers,
      body: JSON.stringify({ content }),
    };

    function req() {
      return {
        type: types.SAVE_CONTENT_REQUEST,
        saving: true,
      };
    }

    function success(contentData) {
      return {
        type: types.SAVE_CONTENT_SUCCESS,
        ...contentData,
      };
    }
    function err(e) {
      let errm;
      if (e.message) {
        errm = e.message;
      } else {
        errm = e.error;
      }
      return {
        type: types.SAVE_CONTENT_ERROR,
        error: errm,
      };
    }

    dispatch(req());
    return dispatch(
      callApi(
        `${reportApiUrl}/reporting/message`,
        config,
        () => {},
        success,
        err,
        true
      )
    );
  };
};

export const editDraft = (draftContent) => {
  return {
    type: types.EDIT_DRAFT,
    draft: draftContent,
  };
};
