import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import auth from '../reducers/auth';
import suppliers from '../reducers/suppliers';
import content from '../reducers/content';
import search from '../reducers/search';
import subscriptions from '../reducers/subscriptions';
import ui from '../reducers/ui';

const logger = createLogger();
const rootReducer = combineReducers({
  auth,
  suppliers,
  content,
  search,
  subscriptions,
  ui,
});

const initialState = {};

export default function configureStore() {
  let store;

  if (module.hot) {
    store = createStore(
      rootReducer,
      initialState,
      compose(
        applyMiddleware(thunkMiddleware, logger),
        window.devToolsExtension ? window.devToolsExtension() : (f) => f
      )
    );
  } else {
    store = createStore(
      rootReducer,
      initialState,
      compose(applyMiddleware(thunkMiddleware), (f) => f)
    );
  }

  return store;
}
