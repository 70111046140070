import React, { Component } from 'react';
import { connect } from 'react-redux';
import { goHome } from '../../actions/auth';
import SubscriptionLookup from './SubscriptionLookup';

import './subscriptions.css';

class SubscriptionsUpdate extends Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {
    const { user } = this.props;
    if (!user.isSuperAdmin()) {
      this.props.goHome();
    }
  }
  render() {
    const { user } = this.props;
    const currentSubscriptionTypeId =
      this.props.match.params.subscriptionTypeId;

    return (
      <div>
        <SubscriptionLookup
          currentSubscriptionTypeId={currentSubscriptionTypeId}
          user={user}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;

  return {
    user,
  };
}

export default connect(mapStateToProps, { goHome })(SubscriptionsUpdate);
