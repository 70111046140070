import React, { Component } from 'react';
import './home.css';
import { connect } from 'react-redux';
import { editDraft } from '../../actions/content';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const toolbarConfig = {
  options: [
    'inline',
    'blockType',
    'fontSize',
    'fontFamily',
    'textAlign',
    'colorPicker',
    'link',
    'history',
  ],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough'],
  },
  colorPicker: {
    colors: [
      'rgb(97,189,109)',
      'rgb(26,188,156)',
      'rgb(2,117,216)',
      'rgb(44,130,201)',
      'rgb(147,101,184)',
      'rgb(71,85,119)',
      'rgb(204,204,204)',
      'rgb(65,168,95)',
      'rgb(0,168,133)',
      'rgb(61,142,185)',
      'rgb(41,105,176)',
      'rgb(85,57,130)',
      'rgb(40,50,78)',
      'rgb(0,0,0)',
      'rgb(247,218,100)',
      'rgb(251,160,38)',
      'rgb(235,107,86)',
      'rgb(226,80,65)',
      'rgb(163,143,132)',
      'rgb(239,239,239)',
      'rgb(255,255,255)',
      'rgb(250,197,28)',
      'rgb(243,121,52)',
      'rgb(209,72,65)',
      'rgb(184,49,47)',
      'rgb(124,112,107)',
      'rgb(209,213,216)',
    ],
  },
};

export class MotdEditor extends Component {
  constructor(props) {
    super(props);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    const content = this.props.draft || this.props.home;

    let editorState;

    if (content.length > 6) {
      const processedHTML = htmlToDraft(content);
      const contentState = ContentState.createFromBlockArray(processedHTML);

      editorState = EditorState.createWithContent(contentState);
      editorState = EditorState.moveFocusToEnd(editorState);
    } else {
      editorState = EditorState.createEmpty();
    }

    this.state = {
      editorState,
    };
  }
  onEditorStateChange(editorState) {
    this.setState({
      editorState,
    });

    const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    this.props.editDraft(content);
  }

  render() {
    const { editorState } = this.state;
    return (
      <div className='motdEditor'>
        <Editor
          toolbar={toolbarConfig}
          editorState={editorState}
          onEditorStateChange={this.onEditorStateChange}
          wrapperClassName='edwrapper'
          editorClassName='editor-class'
          toolbarClassName='toolbar-class'
          readOnly={this.props.saving}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { home, draft, saving } = state.content;
  return {
    home,
    draft,
    saving,
  };
}

export default connect(mapStateToProps, { editDraft })(MotdEditor);
