import * as types from '../actions/types';
import 'isomorphic-fetch';

const DEFAULT_CONTENT = '';

const initialState = {
  home: DEFAULT_CONTENT,
  loading: false,
  edit: false,
  saving: false,
  saveError: null,
  draft: null,
};

const assign = (state, newVars) => {
  return Object.assign({}, state, newVars);
};

export default function content(state = initialState, action = {}) {
  switch (action.type) {
    case types.GET_CONTENT_REQUEST:
      return assign(state, { loading: true });

    case types.GET_CONTENT_SUCCESS: {
      let content = action.content;
      if (content && content.length < 4) {
        content = '<p></p>';
      }
      return assign(state, { loading: false, home: content });
    }

    case types.GET_CONTENT_ERROR:
      return assign(state, { loading: false, home: DEFAULT_CONTENT });

    case types.START_EDIT:
      return assign(state, { edit: true });

    case types.CANCEL_EDIT:
      return assign(state, { edit: false, saveError: null, saving: false });

    case types.SAVE_CONTENT_REQUEST:
      return assign(state, { saving: true, saveError: null });

    case types.SAVE_CONTENT_ERROR:
      return assign(state, { saving: false, saveError: action.error });

    case types.SAVE_CONTENT_SUCCESS:
      return assign(state, {
        edit: false,
        saving: false,
        saveError: null,
        home: action.content,
      });

    case types.EDIT_DRAFT:
      return assign(state, { draft: action.draft });

    default:
      return state;
  }
}
