import React, { Component } from 'react';
import './home.css';
import { connect } from 'react-redux';
import EmployeeLanding from './EmployeeLanding';
import SupplierLanding from './SupplierLanding';
import renderHTML from 'react-render-html';
import {
  getContent,
  startEdit,
  cancelEdit,
  saveContent,
} from '../../actions/content';
import { checkCurrentUserToken } from '../../utils/apiUtils';

import cover1a from './cover1a.jpg';
import cover2a from './cover2a.jpg';
import cover3a from './cover3a.jpg';
import cover3b from './cover3b.jpg';
import withStyles from 'material-ui/styles/withStyles';
import { compose } from 'redux';
import LinearProgress from 'material-ui/Progress/LinearProgress';
import Typography from 'material-ui/Typography/Typography';
import Alert from '../../components/generic/Alert';
import Paper from 'material-ui/Paper/Paper';

const styles = (theme) => ({
  button: {
    '& .fa': {
      marginRight: '1em',
    },
  },
  progressText: {
    marginBottom: '0.5rem',
  },
  loggingIn: {
    marginBottom: '0.8em',
    padding: theme.spacing.unit,
  },
  images: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing.unit * -1,
  },
  image: {
    margin: theme.spacing.unit,
    display: 'flex',
    maxWidth: '100%',
  },
  imageCell: {
    display: 'flex',
  },

  /*
div.images {
  align-content: center;
  display: flex;
  flex-direction: row;
}
*/
});

class DefaultLandingPage extends Component {
  componentWillMount(nextProps) {
    this.props.checkCurrentUserToken();
  }

  render() {
    const { loading, error, classes } = this.props;

    const loginStatus = (
      <Paper className={classes.loggingIn}>
        <Typography variant='subtitle' className={classes.progressText}>
          Logging in ...
        </Typography>
        <LinearProgress data-test-id='progress-indicator' />
      </Paper>
    );

    return (
      <div>
        {' '}
        {error ? <Alert variant='error' message={this.props.error} /> : null}
        {loading ? loginStatus : null}
        {this.props.motd}
      </div>
    );
  }
}

export class AuthenticatedLandingPage extends Component {
  constructor() {
    super();
    this.startEdit = this.startEdit.bind(this);
  }
  componentWillMount(nextProps) {
    this.props.checkCurrentUserToken();
  }

  startEdit() {
    if (!this.props.user || !this.props.user.isContentEditor()) return;
    this.state.newContent = this.props.content;
    this.props.startEdit();
  }
  state = {
    newContent: null,
  };

  render() {
    if (this.props.user && this.props.user.isNordstromEmployee) {
      if (this.props.edit) {
        return this.props.motd;
      }

      return (
        <EmployeeLanding {...this.props} changeEditMode={this.startEdit} />
      );
    } else {
      return (
        <SupplierLanding history={this.props.history} user={this.props.user} />
      );
    }
  }
}
export class Home extends Component {
  constructor(props) {
    super(props);
    this.saveButton = this.saveButton.bind(this);
    this.motd = this.motd.bind(this);
  }

  componentWillMount(nextProps) {
    this.props.checkCurrentUserToken();
  }

  saveButton() {
    if (!this.props.user || !this.props.user.isContentEditor()) return null;
    return;
  }
  save() {
    this.saveContent(this.props.user, this.state.newContent);
  }

  motd() {
    const { content, classes } = this.props;

    return (
      <div className='landing motd'>
        <h1>Welcome to Supplier Reports</h1>
        <div className='generatedContent'>{renderHTML(content)}</div>
        <hr />
        <div className={classes.images}>
          <div className={classes.imageCell}>
            <img className={classes.image} src={cover1a} />
          </div>
          <div className={classes.imageCell}>
            <img className={classes.image} src={cover3b} />
          </div>
          <div className={classes.imageCell}>
            <img className={classes.image} src={cover3a} />
          </div>
          <div className={classes.imageCell}>
            <img className={classes.image} src={cover2a} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { user, classes, loggingIn, content } = this.props;

    if (user) {
      return <AuthenticatedLandingPage {...this.props} motd={this.motd()} />;
    }

    return (
      <DefaultLandingPage
        {...this.props}
        checkCurrentUserToken={this.props.checkCurrentUserToken}
        error={this.props.error}
        motd={this.motd()}
        history={this.props.history}
        content={this.props.content}
      />
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, user, error } = state.auth;
  const { home, edit, saveError, saving, draft } = state.content;
  return {
    loading: loggingIn,
    content: home,
    user,
    edit,
    saving,
    saveError,
    draft,
    error,
  };
}
export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    startEdit,
    getContent,
    cancelEdit,
    saveContent,
    checkCurrentUserToken,
  })
)(Home);
