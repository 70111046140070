import * as types from '../actions/types';

const INITIAL_STATE = {
  subscriptionTypes: [],
  subscriptionDetail: {
    feeExempt: false,
    status: 'INACTIVE',
  },
  success: false,
  extractError: {},
  extractLoading: {},
};

export default function subscriptions(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_SUBSCRIPTION_TYPES_REQUEST: {
      return Object.assign({}, state, {
        loadingSubscriptionTypes: true,
        subscriptionTypes: [],
        error: null,
        success: false,
      });
    }
    case types.GET_SUBSCRIPTION_TYPES_SUCCESS: {
      return (
        Object.assign({}),
        state,
        {
          loadingSubscriptionTypes: false,
          subscriptionTypes: action.payload,
          error: null,
        }
      );
    }
    case types.GET_SUBSCRIPTION_TYPES_ERROR: {
      return Object.assign({}, state, {
        loadingSubscriptionTypes: false,
        subscriptionTypes: [],
        error: action.error,
      });
    }
    case types.SELECT_SUBSCRIPTION_DETAIL_REQUEST: {
      return Object.assign({}, state, {
        loadingSubscriptionDetail: true,
        subscriptionDetail: null,
        success: false,
        vendorNumber: action.vendorNumber,
        subscriptionTypeId: action.subscriptionTypeId,
      });
    }
    case types.SELECT_SUBSCRIPTION_DETAIL_SUCCESS: {
      return Object.assign({}, state, {
        loadingSubscriptionDetail: false,
        subscriptionDetail: action.payload,
        error: null,
      });
    }
    case types.SELECT_SUBSCRIPTION_DETAIL_ERROR: {
      return Object.assign({}, state, {
        loadingSubscriptionDetail: false,
        subscriptionDetail: null,
        error: action.error,
      });
    }

    case types.UPSERT_SUBSCRIPTION_REQUEST: {
      return Object.assign({}, state, {
        loadingSubscriptionDetail: true,
        success: false,
      });
    }
    case types.UPSERT_SUBSCRIPTION_SUCCESS: {
      return Object.assign({}, state, {
        loadingSubscriptionDetail: false,
        subscriptionDetail: action.payload,
        error: null,
        success: true,
      });
    }
    case types.UPSERT_SUBSCRIPTION_ERROR: {
      return Object.assign({}, state, {
        loadingSubscriptionDetail: false,
        error: action.error,
        success: false,
      });
    }
    case types.SUBSCRIPTION_DETAIL_RESET: {
      return Object.assign({}, state, {
        loadingSubscriptionDetail: false,
        subscriptionDetail: null,
        error: null,
        success: false,
      });
    }
    case types.EXTRACT_ERROR:
      return Object.assign({}, state, {
        extractError: { [action.code]: action.error },
        extractLoading: { [action.code]: false },
      });

    case types.EXTRACT_REQUEST:
      return Object.assign({}, state, {
        extractError: { [action.code]: null },
        extractLoading: { [action.code]: true },
      });

    case types.EXTRACT_SUCCESS:
      return Object.assign({}, state, {
        extractError: { [action.code]: null },
        extractLoading: { [action.code]: false },
      });

    default:
      return state;
  }
}
