import React, { Component } from 'react';
import { connect } from 'react-redux';
import './search_results.css';
import { clearSearch } from '../../actions/search';
import * as _ from 'lodash';
import { compose } from 'redux';
import { withStyles } from 'material-ui/styles';
import Typography from 'material-ui/Typography/Typography';
import Table from 'material-ui/Table/Table';
import Paper from 'material-ui/Paper/Paper';
import TableHead from 'material-ui/Table/TableHead';
import TableRow from 'material-ui/Table/TableRow';
import TableCell from 'material-ui/Table/TableCell';
import TableBody from 'material-ui/Table/TableBody';

const styles = (theme) => ({
  root: {},
  paper: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    overflowY: 'scroll',
    maxHeight: '24rem',
  },
  table: {},
  head: {
    'position': 'sticky',
    'top': 0,
    'zIndex': 10,
    '& th': {
      fontSize: '18px',
      fontWeight: 500,
    },
  },
  row: {
    '&:hover': {
      backgroundColor: theme.palette.highlight,
      cursor: 'pointer',
    },
  },
});

class SearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayResults: [],
    };
  }

  componentWillMount() {
    //  this.props.clearSearch();
  }

  renderTable() {
    const { results, classes, history } = this.props;

    const filtered = _.chain(results)
      .filter(['roleName', 'VENDOR ORDER FROM'])
      .sortBy(['vendorName'])
      .value();

    this.state.displayResults = filtered;

    if (filtered.length > 0) {
      return (
        <div className='searchResults'>
          <Typography variant='subheading'>
            Search results (<b>{filtered.length}</b> records found)
          </Typography>
          <Paper className={classes.paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow key='heading1' className={classes.head}>
                  <TableCell>Vendor Name</TableCell>
                  <TableCell>Vendor Number</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.displayResults.map((row, i) => {
                  return (
                    <TableRow
                      key={i}
                      className={classes.row}
                      onClick={() => {
                        // window.location = "/#/reports/"+row.vendorNumber
                        history.push('/reports/' + row.vendorNumber);
                      }}
                    >
                      <TableCell>{row.vendorName}</TableCell>
                      <TableCell>{row.vendorNumber}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: '1em' }} className='alert alert-warning'>
          Your search returned no results
        </div>
      );
    }
  }

  render() {
    return this.props.results && this.props.complete
      ? this.renderTable()
      : null;
  }
}
const mapStateToProps = (state) => {
  const { error, loading, results, searchType, keepState, complete } =
    state.search;

  return {
    user: state.auth.user,
    error,
    loading,
    results,
    searchType,
    keepState,
    complete,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { clearSearch })
)(SearchResults);
